.psgdpr {
    &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &--item {
        width: calc(50% - 20px);
        box-shadow: 0 0 8px rgba($black, 0.16);
        position: relative;
        @include mq(null, $w1440) {
            box-shadow: 0 0 get-vw(8, $regofit-vw) rgba($black, 0.16);
        }
        @include mq(null, $w768) {
            width: 100%;
            box-shadow: 0 0 get-vw(8, $medium-vw) rgba($black, 0.16);
        }
        @include mq(null, $w375) {
            box-shadow: 0 0 get-vw(12, $medium-vw) rgba($black, 0.16);
        }

        &:nth-child(2n) {
            margin-left: 9px;
            @include mq(null, $w1440) {
                margin-left: get-vw(9, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-left: 0;
                margin-top: get-vw(9, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-top: get-vw(13.5, $medium-vw);
            }
        }

        &:nth-child(2n+1) {
            margin-right: 9px;
            @include mq(null, $w1440) {
                margin-right: get-vw(9, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-right: 0;
                margin-bottom: get-vw(9, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-bottom: get-vw(13.5, $medium-vw);
            }
        }

        &--header {
            background-color: $cyan;
            padding: 15px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include mq(null, $w1440) {
                padding: get-vw(15, $regofit-vw) get-vw(20, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: get-vw(15, $medium-vw) get-vw(20, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: get-vw(22.5, $medium-vw) get-vw(30, $medium-vw);
            }

            &--title {
                @include worksans(700);
                @include font-size-lh(25, 29);
                color: $white;
                @include mq(null, $w1440) {
                    @include font-size-vw(25, 29, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(25, 29, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(37.5, 43.5, $medium-vw);
                }
            }

            &--picto {
                svg {
                    @include font-size-lh(40, 40);
                    color: $white;
                    @include mq(null, $w1440) {
                        @include font-size-vw(40, 40, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(40, 40, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(60, 60, $medium-vw);
                    }
                }
            }
        }

        &--inner {
            padding: 15px;
        }

        &--text {
            @include worksans();
            @include font-size-lh(20, 23);
            color: $navy-blue;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }
        }


        &--cta {
            display: flex;
            justify-content: center;
            margin-top: 15px;
            @include mq(null, $w1440) {
                margin-top: get-vw(15, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(15, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-top: get-vw(22.5, $medium-vw);
                flex-direction: column;
                align-items: center;
            }

            &--pdf,
            &--csv {
                background-color: $cyan;
                width: 275px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 3px 6px rgba($black, 0.16);
                margin: 0 9px;
                @include mq(null, $w1440) {
                    width: get-vw(275, $regofit-vw);
                    height: get-vw(60, $regofit-vw);
                    box-shadow: 0 get-vw(3, $regofit-vw) get-vw(6, $regofit-vw) rgba($black, 0.16);
                }
                @include mq(null, $w768) {
                    width: get-vw(275, $medium-vw);
                    height: get-vw(60, $medium-vw);
                    box-shadow: 0 get-vw(3, $medium-vw) get-vw(6, $medium-vw) rgba($black, 0.16);
                }
                @include mq(null, $w375) {
                    width: get-vw(412.5, $medium-vw);
                    height: get-vw(90, $medium-vw);
                    box-shadow: 0 get-vw(4.5, $medium-vw) get-vw(9, $medium-vw) rgba($black, 0.16);
                }
            }

            &--csv {
                @include mq(null, $w375) {
                    margin-top: get-vw(22.5, $medium-vw);
                }
            }

            &--link {
                text-align: center;
                padding: 5px 10px;
                @include worksans();
                @include font-size-lh(20, 23);
                color: $white;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 44.5, $medium-vw);
                }

                &:hover {
                    color: $white;
                }
            }
        }
    }
}