.notifications {
    margin-bottom: 30px;
    @include mq(null, $w1440) {
        margin-bottom: get-vw(30, $regofit-vw);
    }
    @include mq(null, $w768) {
        margin-bottom: get-vw(30, $medium-vw);
    }
    @include mq(null, $w375) {
        margin-bottom: get-vw(45, $medium-vw);
    }

    &--alert {
        border: 1px solid rgba($navy-blue, 0.5);
        padding: 10px 20px;
        @include mq(null, $w1440) {
            padding: get-vw(10, $regofit-vw) get-vw(20, $regofit-vw);
        }
        @include mq(null, $w768) {
            padding: get-vw(10, $medium-vw) get-vw(20, $medium-vw);
        }
        @include mq(null, $w375) {
            padding: get-vw(15, $medium-vw) get-vw(30, $medium-vw);
        }

        &--warning,
        &--danger {
            background-color: rgba($red, 0.3);
            border-color: rgba($red, 0.5);
        }

        &--success {
            background-color: rgba($cyan, 0.3);
            border-color: rgba($cyan, 0.5);
        }

        &--info {
            background-color: rgba($navy-blue, 0.3);
        }
    }

    &--item {
        @include worksans();
        @include font-size-lh(20, 23);
        color: $navy-blue;
        @include mq(null, $w1440) {
            @include font-size-vw(20, 23, $medium-vw);
        }
        @include mq(null, $w768) {
            @include font-size-vw(20, 23, $medium-vw);
        }
        @include mq(null, $w375) {
            @include font-size-vw(30, 34.5, $medium-vw);
        }

        .notifications--alert--danger & {
            color: $red;
        }
    }
}