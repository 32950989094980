.form {
    &--field {
        $widthBtnPassword: 120;
        margin-bottom: 25px;
        @include mq(null, $w1440) {
            margin-bottom: get-vw(25, $regofit-vw);
        }
        @include mq(null, $w768) {
            margin-bottom: get-vw(25, $medium-vw);
        }

        &--create-account--text {
            @include worksans();
            @include font-size-lh(18, 21);
            color: $black;
            @include mq(null, $w1440) {
                @include font-size-vw(18, 21, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(18, 21, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(27, 31.5, $medium-vw);
            }
        }

        &--inner {
            display: flex;
            align-items: center;
        }

        &--wrap {
            &--countrySelect,
            &--select {
                position: relative;

                svg {
                    @include font-size-lh(20, 23);
                    color: $black;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    pointer-events: none;
                    @include mq(null, $w1440) {
                        @include font-size-vw(20, 23, $regofit-vw);
                        right: get-vw(15, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(20, 23, $medium-vw);
                        right: get-vw(15, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(30, 34.5, $medium-vw);
                        right: get-vw(22.5, $medium-vw);
                    }
                }
            }
        }

        &--input {
            @include worksans(700);
            @include font-size-lh(20, 23);
            color: $black;
            width: 100%;
            height: 60px;
            padding: 0 30px;
            border: 2px solid rgba($border, 0.09);
            transition: all 0.3s;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
                height: get-vw(60, $regofit-vw);
                padding: 0 get-vw(30, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
                height: get-vw(60, $medium-vw);
                padding: 0 get-vw(30, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
                height: get-vw(90, $medium-vw);
                padding: 0 get-vw(45, $medium-vw);
            }

            .form--field--wrap--password &,
            .form--field--wrap--search &{
                width: calc(100% - #{$widthBtnPassword}px);
                @include mq(null, $w1440) {
                    width: calc(100% - get-vw($widthBtnPassword, $regofit-vw));
                }
                @include mq(null, $w768) {
                    width: calc(100% - get-vw($widthBtnPassword, $medium-vw));
                }
            }

            &::placeholder {
                color: rgba($black, 0.45);
            }

            &:focus {
                border-color: $border;
            }
        }

        &--password,
        &--search {
            border-right: none;
        }

        &--select {
            @include worksans(700);
            @include font-size-lh(20, 23);
            color: $black;
            width: 100%;
            height: 60px;
            padding: 0 30px;
            flex: 1 1 100%;
            border: 2px solid rgba($border, 0.09);
            transition: all 0.3s;
            appearance: none;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
                height: get-vw(60, $regofit-vw);
                padding: 0 get-vw(30, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
                height: get-vw(60, $medium-vw);
                padding: 0 get-vw(30, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
                height: get-vw(90, $medium-vw);
                padding: 0 get-vw(45, $medium-vw);
            }

            &:focus {
                border-color: $border;
            }
        }

        &--button {
            @include worksans(700);
            @include font-size-lh(20, 23);
            color: $white;
            background-color: $cyan;
            height: 60px;
            border: 3px solid $cyan;
            flex: 1 0 #{$widthBtnPassword}px;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
                height: get-vw(60, $regofit-vw);
                flex-basis: get-vw($widthBtnPassword, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
                height: get-vw(60, $medium-vw);
                flex-basis: get-vw($widthBtnPassword, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
                height: get-vw(90, $medium-vw);
            }
        }

        &--checkbox {
            width: 34px;
            height: 30px;
            border: 2px solid rgba($border, 0.09);
            border-radius: 5px;
            position: relative;
            @include mq(null, $w1440) {
                width: get-vw(34, $regofit-vw);
                height: get-vw(30, $regofit-vw);
                border-radius: get-vw(5, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: get-vw(34, $medium-vw);
                height: get-vw(30, $medium-vw);
                border-radius: get-vw(5, $medium-vw);
            }
            @include mq(null, $w375) {
                width: get-vw(51, $medium-vw);
                height: get-vw(45, $medium-vw);
                border-radius: get-vw(7.5, $medium-vw);
            }

            input {
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;

                &:checked + svg {
                    opacity: 1;
                }
            }

            svg {
                @include font-size-lh(36, 36);
                color: $cyan;
                position: absolute;
                top: -4px;
                left: -1px;
                opacity: 0;
                transition: all 0.3s;
                @include mq(null, $w1440) {
                    @include font-size-vw(36, 36, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(36, 36, $medium-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(54, 54, $medium-vw);
                }
            }
        }

        &--label {
            @include worksans(700);
            @include font-size-lh(18, 21);
            color: $black;
            text-align: left;
            margin-left: 20px;
            flex: 1 1 100%;
            @include mq(null, $w1440) {
                @include font-size-vw(18, 21, $regofit-vw);
                margin-left: get-vw(20, $medium-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(18, 21, $medium-vw);
                margin-left: get-vw(20, $medium-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(27, 31.5, $medium-vw);
                margin-left: get-vw(30, $medium-vw);
            }
        }
    }
}