.contact-form {
    background-color: $navy-blue;
    padding: 0;
    padding-top: 55px;
    padding-bottom: 100px;
    position: relative;
    overflow: hidden;
    @include mq(null, $w1440) {
        padding-top: get-vw(55, $regofit-vw);
        padding-bottom: get-vw(100, $regofit-vw);
    }
    @include mq(null, $w768) {
        padding-top: get-vw(55, $medium-vw);
        padding-bottom: get-vw(100, $medium-vw);
    }
    @include mq(null, $w375) {
        padding-top: get-vw(82.5, $medium-vw);
        padding-bottom: get-vw(150, $medium-vw);
    }

    &--favicon {
        position: absolute;
        pointer-events: none;

        &--before {
            left: -51px;
            bottom: 7px;
            @include mq(null, $w1440) {
                left: get-vw(-51, $regofit-vw);
                bottom: get-vw(7, $regofit-vw);
            }
            @include mq(null, $w768) {
                left: get-vw(-51, $medium-vw);
                bottom: get-vw(7, $medium-vw);
            }
            @include mq(null, $w375) {
                left: get-vw(-76.5, $medium-vw);
                bottom: get-vw(10.5, $medium-vw);
            }

            .favicon {
                width: 377px;
                height: 434px;
                @include mq(null, $w1440) {
                    width: get-vw(377, $regofit-vw);
                    height: get-vw(434, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: get-vw(377, $medium-vw);
                    height: get-vw(434, $medium-vw);
                }
                @include mq(null, $w375) {
                    width: get-vw(565.5, $medium-vw);
                    height: get-vw(651, $medium-vw);
                }
            }
        }

        &--after {
            top: 0;
            right: -130px;
            transform: rotate(-90deg);
            @include mq(null, $w1440) {
                right: get-vw(-130, $regofit-vw);
            }
            @include mq(null, $w768) {
                right: get-vw(-130, $medium-vw);
            }
            @include mq(null, $w375) {
                right: get-vw(-195, $medium-vw);
            }

            .favicon {
                width: 757px;
                height: 657px;
                @include mq(null, $w1440) {
                    width: get-vw(757, $regofit-vw);
                    height: get-vw(657, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: get-vw(757, $medium-vw);
                    height: get-vw(657, $medium-vw);
                }
                @include mq(null, $w375) {
                    width: get-vw(1135.5, $medium-vw);
                    height: get-vw(985.5, $medium-vw);
                }
            }
        }
    }

    &--inner {
        width: 100%;
        max-width: $w1120;
        margin: auto;
    }

    &--titles {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--shop-name {
        @include raleway(900);
        @include font-size-lh(22, 26);
        color: $cyan;
        text-transform: uppercase;
        letter-spacing: 3.07px;
        margin: 0;
        @include mq(null, $w1440) {
            @include font-size-vw(22, 26, $regofit-vw);
            letter-spacing: get-vw(3.07, $regofit-vw);
        }
        @include mq(null, $w768) {
            @include font-size-vw(22, 26, $medium-vw);
            letter-spacing: get-vw(3.07, $medium-vw);
        }
        @include mq(null, $w375) {
            @include font-size-vw(33, 39, $medium-vw);
            letter-spacing: get-vw(4.605, $medium-vw);
        }
    }

    &--title {
        @include raleway(900);
        @include font-size-lh(60, 70);
        color: $white;
        text-transform: uppercase;
        margin: 0;
        background-color: $navy-blue;
        position: relative;
        @include mq(null, $w1440) {
            @include font-size-vw(60, 70, $regofit-vw);
        }
        @include mq(null, $w768) {
            @include font-size-vw(60, 70, $medium-vw);
        }
        @include mq(null, $w375) {
            @include font-size-vw(90, 105, $medium-vw);
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 26px;
            width: 250px;
            height: 5px;
            background-color: $white;
            @include mq(null, $w1440) {
                top: get-vw(26, $regofit-vw);
                width: get-vw(250, $regofit-vw);
                height: get-vw(5, $regofit-vw);
            }
            @include mq(null, $w768) {
                top: get-vw(26, $medium-vw);
                width: get-vw(250, $medium-vw);
                height: get-vw(5, $medium-vw);
            }
            @include mq(null, $w375) {
                top: get-vw(39, $medium-vw);
                width: get-vw(375, $medium-vw);
                height: get-vw(7.5, $medium-vw);
            }
        }

        &:before {
            left: -300px;
            @include mq(null, $w1440) {
                left: get-vw(-300, $regofit-vw);
            }
            @include mq(null, $w768) {
                left: get-vw(-300, $medium-vw);
            }
            @include mq(null, $w375) {
                left: get-vw(-450, $medium-vw);
            }
        }

        &:after {
            right: -300px;
            @include mq(null, $w1440) {
                right: get-vw(-300, $regofit-vw);
            }
            @include mq(null, $w768) {
                right: get-vw(-300, $medium-vw);
            }
            @include mq(null, $w375) {
                right: get-vw(-450, $medium-vw);
            }
        }
    }

    &--text {
        text-align: center;
        margin-top: 12px;
        color: $white;
        @include mq(null, $w1440) {
            margin-top: get-vw(12, $regofit-vw);
        }
        @include mq(null, $w768) {
            margin-top: get-vw(12, $medium-vw);
        }
        @include mq(null, $w375) {
            margin-top: get-vw(18, $medium-vw);
        }

        p {
            color: inherit;
            @include worksans();
            @include font-size-lh(18, 30);
            @include mq(null, $w1440) {
                @include font-size-vw(18, 30, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(18, 30, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(27, 45, $medium-vw);
            }
        }
    }

    &--fields {
        &--inputs {
            display: flex;
            flex-wrap: wrap;
        }

        &--input,
        &--textarea,
        &--button {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            @include mq(null, $w1440) {
                padding: 0 get-vw(20, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: 0 get-vw(20, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: 0 get-vw(30, $medium-vw);
            }
        }

        &--input {
            width: 50%;
            margin-bottom: 30px;
            @include mq(null, $w1440) {
                margin-bottom: get-vw(30, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: 100%;
                margin-bottom: get-vw(30, $medium-vw);
            }
            @include mq(null, $w375) {
                width: 100%;
                margin-bottom: get-vw(45, $medium-vw);
            }
        }

        &--gdpr {
            display: flex;
            padding: 0 20px;
            @include mq(null, $w1440) {
                padding: 0 get-vw(20, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: 0 get-vw(20, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: 0 get-vw(30, $medium-vw);
            }
        }

        &--textarea {
            width: 100%;
        }
    }

    &--field {
        &--label {
            @include worksans(600);
            @include font-size-lh(18, 30);
            color: $white;
            align-self: flex-start;
            margin-bottom: 14px;
            @include mq(null, $w1440) {
                @include font-size-vw(18, 30, $regofit-vw);
                margin-bottom: get-vw(14, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(18, 30, $medium-vw);
                margin-bottom: get-vw(14, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(27, 45, $medium-vw);
                margin-bottom: get-vw(21, $medium-vw);
            }
        }

        &--input,
        &--textarea {
            @include worksans(600);
            @include font-size-lh(18, 30);
            color: $navy-blue;
            background-color: $white;
            border: 1px solid $white;
            width: 100%;
            padding: 0 10px;
            outline: 0;
            @include mq(null, $w1440) {
                @include font-size-vw(18, 30, $regofit-vw);
                padding: 0 get-vw(5, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(18, 30, $medium-vw);
                padding: 0 get-vw(5, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(27, 45, $medium-vw);
                padding: 0 get-vw(7.5, $medium-vw);
            }
        }

        &--input {
            border-radius: 11px;
            height: 68px;
            @include mq(null, $w1440) {
                border-radius: get-vw(11, $regofit-vw);
                height: get-vw(68, $regofit-vw);
            }
            @include mq(null, $w768) {
                border-radius: get-vw(11, $medium-vw);
                height: get-vw(68, $medium-vw);
            }
            @include mq(null, $w375) {
                border-radius: get-vw(16.5, $medium-vw);
                height: get-vw(102, $medium-vw);
            }
        }

        &--gdpr {
            margin-bottom: 30px;
            @include mq(null, $w1440) {
                margin-bottom: get-vw(30, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-bottom: get-vw(30, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-bottom: get-vw(45, $medium-vw);
            }

            .custom-checkbox {
                input {
                    &[type="checkbox"] + span {
                        width: 21px;
                        height: 21px;
                        border: 1px solid $white;
                        border-radius: 7px;
                        background-color: $white;
                        @include mq(null, $w1440) {
                            width: get-vw(21, $regofit-vw);
                            height: get-vw(21, $regofit-vw);
                            border-radius: get-vw(7, $regofit-vw);
                        }
                        @include mq(null, $w768) {
                            width: get-vw(21, $medium-vw);
                            height: get-vw(21, $medium-vw);
                            border-radius: get-vw(7, $medium-vw);
                        }
                        @include mq(null, $w375) {
                            width: get-vw(31.5, $medium-vw);
                            height: get-vw(31.5, $medium-vw);
                            border-radius: get-vw(21, $medium-vw);
                        }

                        .checkbox-checked {
                            @include font-size-lh(19, 19);
                            color: $navy-blue;
                            margin: 0;
                            @include mq(null, $w1440) {
                                @include font-size-vw(19, 19, $regofit-vw);
                            }
                            @include mq(null, $w768) {
                                @include font-size-vw(19, 19, $medium-vw);
                            }
                            @include mq(null, $w375) {
                                @include font-size-vw(28.5, 28.5, $medium-vw);
                            }
                        }
                    }
                }
            }

            label {
                @include worksans();
                @include font-size-lh(18, 30);
                color: $white;
                @include mq(null, $w1440) {
                    @include font-size-vw(18, 30, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(18, 30, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(27, 45, $medium-vw);
                }
            }
        }

        &--textarea {
            padding-top: 15px;
            padding-bottom: 15px;
            border-radius: 11px 11px 0 0;
            height: 225px;
            @include mq(null, $w1440) {
                border-radius: get-vw(11, $regofit-vw) get-vw(11, $regofit-vw) 0 0;
                height: get-vw(225, $regofit-vw);
            }
            @include mq(null, $w768) {
                border-radius: get-vw(11, $medium-vw) get-vw(11, $medium-vw) 0 0;
                height: get-vw(225, $medium-vw);
            }
            @include mq(null, $w375) {
                border-radius: get-vw(16.5, $medium-vw) get-vw(16.5, $medium-vw) 0 0;
                height: get-vw(337.5, $medium-vw);
            }
        }

        &--button {
            @include raleway(900);
            @include font-size-lh(22, 26);
            color: $white;
            text-transform: uppercase;
            letter-spacing: 3.07px;
            background-color: $cyan;
            border: none;
            border-radius: 0 0 11px 11px;
            width: 100%;
            height: 60px;
            @include mq(null, $w1440) {
                @include font-size-vw(22, 26, $regofit-vw);
                letter-spacing: get-vw(3.07, $regofit-vw);
                border-radius: 0 0 get-vw(11, $regofit-vw) get-vw(11, $regofit-vw);
                height: get-vw(60, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(22, 26, $medium-vw);
                letter-spacing: get-vw(3.07, $medium-vw);
                border-radius: 0 0 get-vw(11, $medium-vw) get-vw(11, $medium-vw);
                height: get-vw(60, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(33, 39, $medium-vw);
                letter-spacing: get-vw(4.605, $medium-vw);
                border-radius: 0 0 get-vw(16.5, $medium-vw) get-vw(16.5, $medium-vw);
                height: get-vw(90, $medium-vw);
            }
        }
    }
}