#header {
    position: relative;
    z-index: 100;
}

.header {
    background-color: $navy-blue;
    position: relative;
    z-index: 10;

    &--scrolled {
        z-index: 999;
        padding-bottom: 104px;
        @include mq(null, $w1440) {
            padding-bottom: get-vw(104, $regofit-vw);
        }
        @include mq(null, $w768) {
            padding-bottom: get-vw(104, $medium-vw);
        }
    }

    &--rgpd {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1000;
    }

    &--inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        @include mq(null, $w1440) {
            padding-top: get-vw(50, $regofit-vw);
        }
        @include mq(null, $w768) {
            padding: get-vw(25, $medium-vw) 0;
        }

        .page-my-account &,
        .page-idenbtity &,
        .page-addresses &,
        .page-history &,
        .page-order-detail,
        .page-order-slip &,
        .page-customer-account &,
        .page-cart &,
        .page-order & {
            display: none;
            @include mq(null, $w768) {
                display: block;
            }
        }
    }

    &--logo {
        display: flex;
        justify-content: center;
        @include mq(null, $w768) {
            .header--scrolled & {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                z-index: 99;
                background-color: $navy-blue;
            }
        }

        &--link {
            display: flex;
        }

       .logo-regofit {
           width: 581px;
           height: 134px;
           @include mq(null, $w1440) {
               width: get-vw(581, $regofit-vw);
               height: get-vw(134, $regofit-vw);
           }
           @include mq(null, $w768) {
               width: get-vw(310, $medium-vw);
               height: get-vw(71, $medium-vw);

               .header--scrolled & {
                   width: get-vw(196, $medium-vw);
                   height: get-vw(45, $medium-vw);
                   margin: get-vw(13, $medium-vw) 0;
               }
           }
       }
    }

    &--baseline {
        margin-top: 60px;
        @include mq(null, $w1440) {
            margin-top: get-vw(60, $regofit-vw);
        }
        @include mq(null, $w768) {
            margin-top: get-vw(32, $medium-vw);
        }
        @include mq(null, $w375) {
            display: none;
        }

        .header--home & {
            margin-bottom: 255px;
            @include mq(null, $w1440) {
                margin-bottom: get-vw(255, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-bottom: get-vw(32, $medium-vw);
            }
        }

        &--text {
            @include raleway(900);
            @include font-size-lh(22, 35);
            color: $white;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 3.4px;
            margin: 0;
            @include mq(null, $w1440) {
                @include font-size-vw(22, 35, $regofit-vw);
                letter-spacing: get-vw(3.4, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(10.5, 16, $medium-vw);
                letter-spacing: get-vw(3.4, $medium-vw);
            }
        }
    }

    &--account {
        position: fixed;
        top: 118px;
        right: -57px;
        transition: all 0.3s;
        @include mq(null, $w1440) {
            top: get-vw(118, $regofit-vw);
            right: get-vw(-57, $regofit-vw);
        }
        @include mq(null, $w768) {
            top: get-vw(74, $medium-vw);
            right: get-vw(-30, $medium-vw);
        }

        .header--scrolled & {
            top: 74px;
            z-index: 999;
            @include mq(null, $w1440) {
                top: get-vw(74, $regofit-vw);
            }
            @include mq(null, $w768) {
                top: get-vw(117, $medium-vw);
            }
        }

        &--cta {
            background-color: $cyan;
            width: 117px;
            height: 60px;
            border-radius: 60px 0 0 60px;
            @include mq(null, $w1440) {
                width: get-vw(117, $regofit-vw);
                height: get-vw(60, $regofit-vw);
                border-radius: get-vw(60, $regofit-vw) 0 0 get-vw(60, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: get-vw(87.75, $medium-vw);
                height: get-vw(45, $medium-vw);
                border-radius: get-vw(45, $medium-vw) 0 0 get-vw(45, $medium-vw);
            }
            @include mq(null, $w375) {
                width: get-vw(117, $medium-vw);
                height: get-vw(60, $medium-vw);
                border-radius: get-vw(60, $medium-vw) 0 0 get-vw(60, $medium-vw);
            }

            &--picto {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
                @include mq(null, $w1440) {
                    width: get-vw(60, $regofit-vw);
                    height: get-vw(60, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: get-vw(45, $medium-vw);
                    height: get-vw(45, $medium-vw);
                }
                @include mq(null, $w375) {
                    width: get-vw(60, $medium-vw);
                    height: get-vw(60, $medium-vw);
                }

                svg {
                    color: $navy-blue;
                    width: 25px !important;
                    height: 29px;
                    @include mq(null, $w1440) {
                        width: get-vw(25, $regofit-vw) !important;
                        height: get-vw(29, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        width: get-vw(18.75, $medium-vw) !important;
                        height: get-vw(21.75, $medium-vw);
                    }
                    @include mq(null, $w768) {
                        width: get-vw(25, $medium-vw) !important;
                        height: get-vw(29, $medium-vw);
                    }
                }
            }
        }
    }

    &--favicon {
        position: absolute;
        top: 174px;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, 0%);
        transition: all 0.3s;
        pointer-events: none;
        @include mq(null, $w1440) {
            top: get-vw(174, $regofit-vw);
        }
        @include mq(null, $w768) {
            top: get-vw(174, $medium-vw);
        }

        .header--home & {
            opacity: 1;
        }

        .favicon-header {
            width: 774.8px;
            height: 892.3px;
            @include mq(null, $w1440) {
                width: get-vw(774.8, $regofit-vw);
                height: get-vw(892.3, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: get-vw(413, $medium-vw);
                height: get-vw(476, $medium-vw);
            }
        }
    }

    &--image {
        position: absolute;
        top: 300px;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, 0%);
        transition: all 0.3s;
        pointer-events: none;
        @include mq(null, $w1440) {
            top: get-vw(300, $regofit-vw);
        }
        @include mq(null, $w768) {
            top: get-vw(160, $medium-vw);
        }

        img {
            width: 525px;
            @include mq(null, $w1440) {
                width: get-vw(525, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: get-vw(280, $medium-vw);
                display: none;
            }
        }

        .header--home & {
            opacity: 1;
        }
    }

    &--nav {
        .header--scrolled & {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: 99;
            background-color: $navy-blue;
            @include mq(null, $w768) {
                top: get-vw(71, $medium-vw);
            }
        }

        &--inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 40px 0;
            position: relative;
            @include mq(null, $w1440) {
                padding: get-vw(40, $regofit-vw) 0;
            }
            @include mq(null, $w768) {
                justify-content: center;
                padding: get-vw(21, $medium-vw) 0;
            }
        }

        &--logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: all 0.3s;

            .header--scrolled & {
                opacity: 1;
            }

            .logo-regofit {
                width: 210px;
                height: 48px;
                @include mq(null, $w1440) {
                    width: get-vw(210, $regofit-vw);
                    height: get-vw(48, $regofit-vw);
                }
                @include mq(null, $w768) {
                    display: none;
                }
            }
        }

        &--links {
            display: flex;
            align-items: center;
            margin: 0;

            &--li {
                position: relative;
                margin-right: 50px;
                cursor: pointer;
                @include mq(null, $w1440) {
                    margin-right: get-vw(50, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin: 0 get-vw(13, $medium-vw);
                }
                @include mq(null, $w375) {
                    display: none;
                }

                &--home {
                    display: none;
                    @include mq(null, $w375) {
                        margin: 0 get-vw(20.5, $medium-vw);
                        display: block;
                    }

                    svg {
                        @include font-size-lh(18, 18);
                        @include mq(null, $w375) {
                            @include font-size-vw(36, 36, $medium-vw);
                        }
                    }
                }

                &--cart {
                    margin-left: 60px;
                    margin-right: 0;
                    @include mq(null, $w1440) {
                        margin-left: get-vw(60, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        margin: 0 get-vw(13, $medium-vw);
                        display: block;
                    }
                    @include mq(null, $w375) {
                        margin: 0 get-vw(20.5, $medium-vw);
                    }

                    &--nb {
                        @include worksans();
                        @include font-size-lh(12, 12);
                        color: $navy-blue;
                        letter-spacing: 0;
                        position: absolute;
                        top: -10px;
                        left: -10px;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        background-color: $cyan;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include mq(null, $w1440) {
                            @include font-size-vw(12, 12, $regofit-vw);
                            top: get-vw(-10, $regofit-vw);
                            left: get-vw(-10, $regofit-vw);
                            width: get-vw(20, $regofit-vw);
                            height: get-vw(20, $regofit-vw);
                        }
                        @include mq(null, $w768) {
                            @include font-size-vw(12, 12, $medium-vw);
                            top: get-vw(-10, $medium-vw);
                            left: get-vw(-10, $medium-vw);
                            width: get-vw(20, $medium-vw);
                            height: get-vw(20, $medium-vw);
                        }
                        @include mq(null, $w375) {
                            @include font-size-vw(18, 18, $medium-vw);
                            top: get-vw(-15, $medium-vw);
                            left: get-vw(-15, $medium-vw);
                            width: get-vw(30, $medium-vw);
                            height: get-vw(30, $medium-vw);
                        }
                    }
                }
            }

            &--title {
                @include raleway(800);
                @include font-size-lh(20, 24);
                color: $white;
                text-transform: uppercase;
                letter-spacing: 2.22px;
                margin: 0;
                position: relative;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 24, $regofit-vw);
                    letter-spacing: get-vw(2.22, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 24, $medium-vw);
                    letter-spacing: get-vw(2.22, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 36, $medium-vw);
                    letter-spacing: get-vw(3.33, $medium-vw);
                }

                .header--nav--links--li--cart & {
                    color: $cyan;
                }
            }

            &--picto {
                position: absolute;
                bottom: -67;
                left: 50%;
                transform: translate(-50%, 0%);
                opacity: 0;
                transition: all 0.3s;
                pointer-events: none;
                @include mq(null, $w1440) {
                    bottom: get-vw(-67, $regofit-vw);
                }
                @include mq(null, $w768) {
                    bottom: get-vw(-67, $medium-vw);
                }
                @include mq(null, $w375) {
                    bottom: get-vw(-67, $medium-vw);
                }

                .header--nav--links--li--active & {
                    opacity: 1;
                }

                .picto-nav {
                    width: 78.5px;
                    height: 51.588px;
                    @include mq(null, $w1440) {
                        width: get-vw(78.5, $regofit-vw);
                        height: get-vw(51.588, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        width: get-vw(78.5, $medium-vw);
                        height: get-vw(51.588, $medium-vw);
                    }
                }
            }
        }
    }
}