.breadcrumb {
    margin: 20px 0;
    @include mq(null, $w1440) {
        margin: get-vw(20, $regofit-vw) 0;
    }
    @include mq(null, $w768) {
        margin: get-vw(20, $medium-vw) 0;
    }
    @include mq(null, $w375) {
        margin: get-vw(30, $medium-vw) 0;
    }

    &--ol {
        display: flex;
        align-items: center;
    }

    &--li {
        display: flex;

        &:last-child {
            .breadcrumb--title {
                color: $cyan;
            }
        }

        &:after {
            @include worksans();
            @include font-size-lh(20, 23);
            color: $navy-blue;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }
        }
    }

    &--title {
        @include worksans();
        @include font-size-lh(20, 23);
        color: $navy-blue;
        @include mq(null, $w1440) {
            @include font-size-vw(20, 23, $regofit-vw);
        }
        @include mq(null, $w768) {
            @include font-size-vw(20, 23, $medium-vw);
        }
        @include mq(null, $w375) {
            @include font-size-vw(30, 34.5, $medium-vw);
        }
    }
}