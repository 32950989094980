.page {
    &--cms {
        h2, h3, h4, h5, h6, p, li {
            @include worksans();
            color: $navy-blue;
        }

        h2, h3, h4, h5, h6 {
            @include worksans(700);
        }

        p, li {
            @include font-size-lh(18, 21);
            @include mq(null, $w1440) {
                @include font-size-vw(18, 21, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(18, 21, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(27, 31.5, $medium-vw);
            }
        }

        ul {
            list-style: disc;
            padding-left: 2em;
        }
        li {
            margin: 5px 0;
        }
    }
}