.page {
    &--404 {
        width: 75%;
        margin: auto;
        box-shadow: 0 0 8px rgba($black, 0.16);
        position: relative;
        @include mq(null, $w1440) {
            box-shadow: 0 0 get-vw(8, $regofit-vw) rgba($black, 0.16);
        }
        @include mq(null, $w768) {
            width: 100%;
            box-shadow: 0 0 get-vw(8, $medium-vw) rgba($black, 0.16);
        }
        @include mq(null, $w375) {
            box-shadow: 0 0 get-vw(12, $medium-vw) rgba($black, 0.16);
        }

        &--header {
            background-color: $cyan;
            padding: 15px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include mq(null, $w1440) {
                padding: get-vw(15, $regofit-vw) get-vw(20, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: get-vw(15, $medium-vw) get-vw(20, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: get-vw(22.5, $medium-vw) get-vw(30, $medium-vw);
            }

            &--title {
                @include worksans(700);
                @include font-size-lh(25, 29);
                color: $white;
                text-transform: uppercase;
                @include mq(null, $w1440) {
                    @include font-size-vw(25, 29, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(25, 29, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(37.5, 43.5, $medium-vw);
                }
            }

            &--picto {
                svg {
                    @include font-size-lh(40, 40);
                    color: $white;
                    @include mq(null, $w1440) {
                        @include font-size-vw(40, 40, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(40, 40, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(60, 60, $medium-vw);
                    }
                }
            }
        }

        &--content {
            padding: 45px 60px;
            background-color: $white;
            position: relative;
            @include mq(null, $w1440) {
                padding: get-vw(45, $regofit-vw) get-vw(60, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: get-vw(45, $medium-vw) get-vw(60, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: get-vw(45, $medium-vw) get-vw(60, $medium-vw);
            }
        }

        &--text {
            @include worksans();
            @include font-size-lh(20, 23);
            color: $navy-blue;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }
        }
    }
}