$google-sans-path: '../../webfonts';
@font-face {
    font-family: 'GoogleSans-Bold';
    src: url('#{$google-sans-path}/GoogleSans-Bold.woff2') format('woff2'),
    url('#{$google-sans-path}/GoogleSans-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

