.block-reassurance {
    background-color: $cyan;
    padding-top: 65px;
    position: relative;
    overflow: hidden;
    @include mq(null, $w1440) {
        padding-top: get-vw(65, $regofit-vw);
    }
    @include mq(null, $w768) {
        padding-top: get-vw(65, $medium-vw);
    }
    @include mq(null, $w375) {
        padding-top: get-vw(97.5, $medium-vw);
    }

    &--favicon {
        position: absolute;
        top: 50%;
        pointer-events: none;

        &--before {
            left: 60px;
            transform: rotate(-90deg) translate(45%, 0%);
            @include mq(null, $w1440) {
                left: get-vw(60, $regofit-vw);
            }
            @include mq(null, $w768) {
                left: get-vw(60, $medium-vw);
            }
            @include mq(null, $w375) {
                left: get-vw(90, $medium-vw);
            }
        }

        &--after {
            right: 60px;
            transform: rotate(90deg) translate(-45%, 0%);
            @include mq(null, $w1440) {
                right: get-vw(60, $regofit-vw);
            }
            @include mq(null, $w768) {
                right: get-vw(60, $medium-vw);
            }
            @include mq(null, $w375) {
                right: get-vw(90, $medium-vw);
            }
        }

        .favicon {
            width: 277px;
            height: 240px;
            @include mq(null, $w1440) {
                width: get-vw(277, $regofit-vw);
                height: get-vw(240, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: get-vw(277, $medium-vw);
                height: get-vw(240, $medium-vw);
            }
            @include mq(null, $w375) {
                width: get-vw(415.5, $medium-vw);
                height: get-vw(360, $medium-vw);
            }

            .cls-1 {
                opacity: 0.85;
            }

            .cls-2 {
                fill: none;
                stroke: $white;
                stroke-width: 0.5px;
            }
        }
    }

    &--items {
        display: flex;
        flex-wrap: wrap;
    }

    &--item {
        text-align: center;
        padding: 0 20px;
        width: 25%;
        margin-bottom: 65px;
        @include mq(null, $w1440) {
            padding: 0 get-vw(20, $regofit-vw);
            margin-bottom: get-vw(65, $regofit-vw);
        }
        @include mq(null, $w768) {
            width: 50%;
            padding: 0 get-vw(20, $medium-vw);
            margin-bottom: get-vw(65, $medium-vw);
        }
        @include mq(null, $w375) {
            width: 50%;
            margin-bottom: get-vw(97.5, $medium-vw);
        }
    }

    &--img {
        margin-bottom: 15px;
        position: relative;
        height: 86px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq(null, $w1440) {
            margin-bottom: get-vw(15, $regofit-vw);
            height: get-vw(86, $regofit-vw);
        }
        @include mq(null, $w768) {
            margin-bottom: get-vw(15, $medium-vw);
            height: get-vw(86, $medium-vw);
        }
        @include mq(null, $w375) {
            margin-bottom: get-vw(22.5, $medium-vw);
            height: get-vw(129, $medium-vw);
        }

        img {
            height: 100%;
        }
    }

    &--flag {
        position: absolute;
        bottom: -10px;
        right: 66px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $white;
        margin: 0 6px;
        @include mq(null, $w1440) {
            bottom: get-vw(-10, $regofit-vw);
            right: get-vw(66, $regofit-vw);
            width: get-vw(10, $regofit-vw);
            height: get-vw(10, $regofit-vw);
            margin: 0 get-vw(6, $regofit-vw);
        }
        @include mq(null, $w768) {
            bottom: get-vw(-10, $medium-vw);
            right: get-vw(66, $medium-vw);
            width: get-vw(10, $medium-vw);
            height: get-vw(10, $medium-vw);
            margin: 0 get-vw(6, $medium-vw);
        }
        @include mq(null, $w375) {
            bottom: get-vw(-15, $medium-vw);
            right: get-vw(99, $medium-vw);
            width: get-vw(15, $medium-vw);
            height: get-vw(15, $medium-vw);
            margin: 0 get-vw(9, $medium-vw);
        }

        &:before,
        &:after {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            @include mq(null, $w1440) {
                width: get-vw(10, $regofit-vw);
                height: get-vw(10, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: get-vw(10, $medium-vw);
                height: get-vw(10, $medium-vw);
            }
            @include mq(null, $w375) {
                width: get-vw(15, $medium-vw);
                height: get-vw(15, $medium-vw);
            }
        }

        &:before {
            background-color: $blue;
            left: -16px;
            @include mq(null, $w1440) {
                left: get-vw(-16, $regofit-vw);
            }
            @include mq(null, $w768) {
                left: get-vw(-16, $medium-vw);
            }
            @include mq(null, $w375) {
                left: get-vw(-24, $medium-vw);
            }
        }

        &:after {
            background-color: $red;
            right: -16px;
            @include mq(null, $w1440) {
                right: get-vw(-16, $regofit-vw);
            }
            @include mq(null, $w768) {
                right: get-vw(-16, $medium-vw);
            }
            @include mq(null, $w375) {
                right: get-vw(-24, $medium-vw);
            }
        }
    }

    &--title {
        @include raleway(900);
        @include font-size-lh(22, 26);
        color: $white;
        text-transform: uppercase;
        letter-spacing: 3.07px;
        margin: 0;
        @include mq(null, $w1440) {
            @include font-size-vw(22, 26, $regofit-vw);
            letter-spacing: get-vw(3.07, $regofit-vw);
        }
        @include mq(null, $w768) {
            @include font-size-vw(22, 26, $medium-vw);
            letter-spacing: get-vw(3.07, $medium-vw);
        }
        @include mq(null, $w375) {
            @include font-size-vw(33, 39, $medium-vw);
            letter-spacing: get-vw(4.605, $medium-vw);
        }
    }

    &--text {
        margin-top: 15px;
        @include mq(null, $w1440) {
            margin-top: get-vw(15, $regofit-vw);
        }
        @include mq(null, $w768) {
            margin-top: get-vw(15, $medium-vw);
        }
        @include mq(null, $w375) {
            margin-top: get-vw(22.5, $medium-vw);
        }

        p {
            @include worksans();
            @include font-size-lh(18, 22);
            color: $white;
            margin: 0;
            @include mq(null, $w1440) {
                @include font-size-vw(18, 22, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(18, 22, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(27, 33, $medium-vw);
            }
        }
    }
}