@import "partials/mixins";
@import "partials/variables";
@import "partials/typography";
@import "partials/functions";
/* ----------------------------- */
@import "fontawesome/fontawesome";
//@import "fontawesome/solid";
@import "fontawesome/regular";
//@import "fontawesome/brands";
//@import "fontawesome/light";
//@import "fontawesome/duotone";
/* ----------------------------- */
@import "webfonts/google-sans";
/* ----------------------------- */
@import "components/header";
@import "components/footer";
@import "components/reassurance";
@import "components/contact";
@import "components/push-presentation";
@import "components/push-product";
@import "components/push-features";
@import "components/account";
@import "components/cart";
@import "components/breadcrumb";
@import "components/notifications";
@import "components/form-fields";
@import "components/psgdpr";
@import "components/cms";
@import "components/404";
@import "components/search";

*,
*:after,
*:before {
    @include box-sizing(border-box);
}

.visuallyhidden {
    @include visuallyhidden;
}

.clearfix {
    @include clearfix;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

#wrapper {
    background-color: $white;
    padding-top: 0;
    box-shadow: none;
}

.container {
    width: 100%;
    max-width: $w1440;

    .page-index & {
        max-width: 100%;
        padding: 0;
    }

    &--inner {
        width: 100%;
        max-width: $w1440;
        margin: auto;
        padding: 0 20px;

        .footer & {
            max-width: $w1120;
            padding: 0 20px;
        }
    }
}

#v-cookielaw {
    overflow: hidden;
    background-color: $white;
    color: #444;
    font-size: 13px;

    .container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        @include mq(null, 1023px) {
            flex-direction: column;
        }
    }

    &.v-bar {
        padding: 4px 0;

        &.v-top {
            position: relative;
            border-bottom: 1px solid $black;
        }

        .v-actions {
            display: flex;
            align-items: center;
            text-align: right;
            margin: 10px 0;
        }
    }

    &.v-yellow-alert {
        background-color: #fffbcd;
        border-color: #e2cd7b !important;
    }

    .v-message {
        padding: 0 10px;
        text-align: left;
    }

    .v-button {
        white-space: nowrap;
        margin-left: 5px;
        color: #222;
        display: inline-block;
        background-color: #ececec;
        background-image: linear-gradient(#f4f4f4, #ececec);
        border: 1px solid #d4d4d4;
        padding: 0 10px;
        text-decoration: none;
        text-shadow: 1px 1px 0 #fff;
        border-radius: 2px;

        &.v-accept::before {
            content: "\2714 ";
        }
    }
}

#main {
    .page-footer {
        display: none;

        .page-my-account &,
        .page-password & {
            display: block;
        }
    }
}

.page {
    &--header {
        margin: 40px 0;
        position: relative;
        @include mq(null, $w1440) {
            margin: get-vw(40, $regofit-vw) 0;
        }
        @include mq(null, $w768) {
            margin: get-vw(40, $medium-vw) 0;
        }
        @include mq(null, $w375) {
            margin: get-vw(60, $medium-vw) 0;
        }

        &--title {
            @include poppins(700);
            @include font-size-lh(30, 45);
            color: $black;
            text-transform: uppercase;
            display: inline-flex;
            margin: 0;
            padding-right: 8px;
            background-color: $white;
            position: relative;
            z-index: 2;
            @include mq(null, $w1440) {
                @include font-size-vw(30, 45, $regofit-vw);
                padding-right: get-vw(8, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(30, 45, $medium-vw);
                padding-right: get-vw(8, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(45, 67.5, $medium-vw);
                padding-right: get-vw(12, $medium-vw);
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            background-color: $cyan;
            width: 100%;
            height: 5px;
            transform: translateY(-50%);
            @include mq(null, $w1440) {
                height: get-vw(5, $regofit-vw);
            }
            @include mq(null, $w768) {
                height: get-vw(5, $medium-vw);
            }
            @include mq(null, $w375) {
                height: get-vw(7.5, $medium-vw);
            }
        }
    }

    &--content {
        margin-bottom: 70px;
        @include mq(null, $w1440) {
            margin-bottom: get-vw(70, $regofit-vw);
        }
        @include mq(null, $w768) {
            margin-bottom: get-vw(70, $medium-vw);
        }
        @include mq(null, $w375) {
            margin-bottom: get-vw(105, $medium-vw);
        }
    }
}

#blockcart-modal {
    .modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        margin: 0 auto;
    }

    .modal-content {
        width: 100%;
    }
}

.page-customer-account {
    #content {
        box-shadow: none;
        padding: 0;
    }
}

.input-group.focus {
    outline: none;
}

hr {
    border-color: rgba($black, 0.16);
}

.definition-list dl {
    dd, dt {
        @include worksans();
        @include font-size-lh(18, 21);
        color: $black;
        @include mq(null, $w1440) {
            @include font-size-vw(18, 21, $regofit-vw);
        }
        @include mq(null, $w768) {
            @include font-size-vw(18, 21, $medium-vw);
        }
        @include mq(null, $w375) {
            @include font-size-vw(27, 31.5, $medium-vw);
        }
    }
}