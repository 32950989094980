.page {
    &--account {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include mq(null, $w768) {
            flex-wrap: wrap;
        }
    }

    &--customer--account {
        &--links {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &--link {
            $margin: get-vw(10, $regofit-vw);
            $margin768: get-vw(10, $medium-vw);
            width: calc(50% - 20px);
            box-shadow: 0 0 8px rgba($black, 0.16);
            margin-bottom: 20px;
            @include mq(null, $w1440) {
                width: calc(50% - 2 * #{$margin});
                box-shadow: 0 0 get-vw(8, $regofit-vw) rgba($black, 0.16);
                margin-bottom: get-vw(20, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: 100%;
                box-shadow: 0 0 get-vw(8, $medium-vw) rgba($black, 0.16);
                margin-bottom: get-vw(20, $medium-vw);
            }
            @include mq(null, $w375) {
                box-shadow: 0 0 get-vw(12, $medium-vw) rgba($black, 0.16);
                margin-bottom: get-vw(30, $medium-vw);
            }

            &:nth-child(2n) {
                margin-left: 10px;
                @include mq(null, $w1440) {
                    margin-left: #{$margin};
                }
                @include mq(null, $w768) {
                    margin-left: 0;
                }
            }

            &:nth-child(2n+1) {
                margin-right: 10px;
                @include mq(null, $w1440) {
                    margin-right: #{$margin};
                }
                @include mq(null, $w768) {
                    margin-right: 0;
                }
            }

            &--url {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 20px;
                @include mq(null, $w1440) {
                    padding: get-vw(15, $regofit-vw) get-vw(20, $regofit-vw);
                }
                @include mq(null, $w768) {
                    padding: get-vw(15, $medium-vw) get-vw(20, $medium-vw);
                }
                @include mq(null, $w375) {
                    padding: get-vw(22.5, $medium-vw) get-vw(30, $medium-vw);
                }
            }

            &--title {
                @include worksans(700);
                @include font-size-lh(25, 29);
                color: $cyan;
                @include mq(null, $w1440) {
                    @include font-size-vw(25, 29, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(25, 29, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(37.5, 43.5, $medium-vw);
                }
            }

            &--picto {
                @include mq(null, $w768) {
                    display: flex;
                    justify-content: center;
                    width: get-vw(30, $medium-vw);
                }

                svg,
                i {
                    @include font-size-lh(30, 30);
                    color: $cyan;
                    @include mq(null, $w1440) {
                        @include font-size-vw(30, 30, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(30, 30, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(45, 45, $medium-vw);
                    }
                }
            }
        }
    }
}

.account {
    &--forms {
        width: 75%;
        box-shadow: 0 0 8px rgba($black, 0.16);
        position: relative;
        @include mq(null, $w1440) {
            box-shadow: 0 0 get-vw(8, $regofit-vw) rgba($black, 0.16);
        }
        @include mq(null, $w768) {
            display: none;
            width: 100%;
            box-shadow: 0 0 get-vw(8, $medium-vw) rgba($black, 0.16);
        }
        @include mq(null, $w375) {
            box-shadow: 0 0 get-vw(12, $medium-vw) rgba($black, 0.16);
        }

        &--active {
            display: block;
        }

        &--unactive {
            display: none;

            @include mq(769px, null) {
                .page--account & {
                    display: block;
                }
            }
        }

        &--navigation {
            display: none;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            @include mq(null, $w1440) {
                margin-bottom: get-vw(30, $regofit-vw);
            }
            @include mq(null, $w768) {
                display: flex;
                margin-bottom: get-vw(30, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-bottom: get-vw(45, $medium-vw);
            }

            .page--cart--checkout & {
                display: flex;
            }

            &--item {
                padding: 0 15px;
                @include mq(null, $w1440) {
                    padding: 0 get-vw(15, $regofit-vw);
                }
                @include mq(null, $w768) {
                    padding: 0 get-vw(15, $medium-vw);
                }
                @include mq(null, $w375) {
                    padding: 0 get-vw(22.5, $medium-vw);
                }

                &:not(:last-child) {
                    border-right: 2px solid $cyan;
                }
            }

            &--link {
                @include poppins();
                @include font-size-lh(20, 23);
                color: $cyan;
                cursor: pointer;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }

                &--active,
                &:hover {
                    @include poppins(500);
                    color: $navy-blue;
                }
            }
        }

        &--address {
            display: block;
        }

        &--forgotten-password {
            @include mq(null, $w768) {
                display: flex;
            }
        }

        &--register {
            width: 50%;
            margin-left: 9px;
            @include mq(null, $w1440) {
                margin-left: get-vw(9, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: 100%;
                margin-left: 0;
            }

            .page--cart--checkout--personal-information & {
                width: 75%;
                margin: 0;
            }
        }

        &--login {
            width: 50%;
            margin-right: 9px;
            @include mq(null, $w1440) {
                margin-right: get-vw(9, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: 100%;
                margin-right: 0;
            }

            .page--cart--checkout--personal-information & {
                width: 75%;
                margin: 0;
            }
        }

        &--favicon {
            position: absolute;
            top: 100px;
            right: -240px;
            transform: rotate(90deg);
            @include mq(null, $w1440) {
                top: get-vw(100, $regofit-vw);
                right: get-vw(-240, $regofit-vw);
            }
            @include mq(null, $w768) {
                top: get-vw(100, $medium-vw);
                right: get-vw(-240, $medium-vw);
                display: none;
            }
            @include mq(null, $w375) {
                top: get-vw(150, $medium-vw);
                right: get-vw(-360, $medium-vw);
            }

            .page--cart--checkout--personal-information & {
                left: -240px;
                right: initial;
                transform: rotate(-90deg);
                @include mq(null, $w1440) {
                    left: get-vw(-240, $regofit-vw);
                }
                @include mq(null, $w768) {
                    left: get-vw(-240, $medium-vw);
                }
                @include mq(null, $w375) {
                    left: get-vw(-360, $medium-vw);
                }
            }

            .favicon {
                width: 528px;
                height: 459px;
                @include mq(null, $w1440) {
                    width: get-vw(528, $regofit-vw);
                    height: get-vw(459, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: get-vw(528, $medium-vw);
                    height: get-vw(459, $medium-vw);
                }
                @include mq(null, $w375) {
                    width: get-vw(792, $medium-vw);
                    height: get-vw(688.5, $medium-vw);
                }

                .cls-1 {
                    opacity: 0.04;
                }

                .cls-2 {
                    fill: $navy-blue;
                }
            }
        }

        &--header {
            background-color: $cyan;
            padding: 15px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include mq(null, $w1440) {
                padding: get-vw(15, $regofit-vw) get-vw(20, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: get-vw(15, $medium-vw) get-vw(20, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: get-vw(22.5, $medium-vw) get-vw(30, $medium-vw);
            }

            &--title {
                @include worksans(700);
                @include font-size-lh(25, 29);
                color: $white;
                text-transform: uppercase;
                @include mq(null, $w1440) {
                    @include font-size-vw(25, 29, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(25, 29, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(37.5, 43.5, $medium-vw);
                }
            }

            &--picto {
                svg {
                    @include font-size-lh(40, 40);
                    color: $white;
                    @include mq(null, $w1440) {
                        @include font-size-vw(40, 40, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(40, 40, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(60, 60, $medium-vw);
                    }
                }
            }
        }
    }

    &--form {
        padding: 45px 60px;
        background-color: $white;
        position: relative;
        @include mq(null, $w1440) {
            padding: get-vw(45, $regofit-vw) get-vw(60, $regofit-vw);
        }
        @include mq(null, $w768) {
            padding: get-vw(45, $medium-vw) get-vw(60, $medium-vw);
        }
        @include mq(null, $w375) {
            padding: get-vw(45, $medium-vw) get-vw(60, $medium-vw);
        }

        &--cta {
            display: flex;
            justify-content: center;
            @include mq(null, $w768) {
                flex-direction: column;
                align-items: center;
            }

            .checkout-personal-information-step &,
            .checkout-addresses-step &,
            .checkout-delivery-step & {
                justify-content: flex-end;
            }

            .checkout-payment-step & {
                justify-content: flex-start;
            }

            &--submit,
            &--forgot-password {
                border: 3px solid $cyan;
                min-width: 275px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include mq(null, $w1440) {
                    min-width: get-vw(275, $regofit-vw);
                    height: get-vw(60, $regofit-vw);
                }
                @include mq(null, $w768) {
                    min-width: get-vw(275, $medium-vw);
                    height: get-vw(60, $medium-vw);
                }
                @include mq(null, $w375) {
                    min-width: get-vw(412.5, $medium-vw);
                    height: get-vw(90, $medium-vw);
                }
            }

            &--submit {
                box-shadow: 0 3px 6px rgba($black, 0.16);
                @include mq(null, $w1440) {
                    box-shadow: 0 get-vw(3, $regofit-vw) get-vw(6, $regofit-vw) rgba($black, 0.16);
                }
                @include mq(null, $w768) {
                    box-shadow: 0 get-vw(3, $medium-vw) get-vw(6, $medium-vw) rgba($black, 0.16);
                    order: -1;
                }
                @include mq(null, $w375) {
                    box-shadow: 0 get-vw(4.5, $medium-vw) get-vw(9, $medium-vw) rgba($black, 0.16);
                }

                .account--form--login & {
                    .page--account & {
                        margin-left: 9px;
                        @include mq(null, $w1440) {
                            margin-left: get-vw(9, $regofit-vw);
                        }
                        @include mq(null, $w768) {
                            margin-left: 0
                        }
                    }
                }

                .checkout-payment-step & {
                    width: auto;
                }
            }

            &--forgot-password {
                .account--form--login & {
                    margin-right: 9px;
                    @include mq(null, $w1440) {
                        margin-right: get-vw(9, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        margin-right: 0;
                        margin-top: get-vw(20, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        margin-top: get-vw(30, $medium-vw);
                    }
                }
            }

            &--link {
                @include worksans();
                @include font-size-lh(20, 23);
                color: $cyan;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }

                .account--form--cta--submit & {
                    @include google-sans-bold;
                    @include font-size-lh(20, 25);
                    color: $white;
                    text-transform: uppercase;
                    background-color: $cyan;
                    border: none;
                    width: 100%;
                    height: 100%;
                    @include mq(null, $w1440) {
                        @include font-size-vw(20, 25, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(20, 25, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(30, 37.5, $medium-vw);
                    }
                }
            }
        }

        &--forgotten-password {
            &--text {
                @include worksans();
                @include font-size-lh(20, 23);
                color: $navy-blue;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }
            }

            &--form-fields {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 30px;
                @include mq(null, $w1440) {
                    margin-top: get-vw(30, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-top: get-vw(30, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(45, $medium-vw);
                }

                .form--field {
                    width: 100%;
                }

            }
        }
    }

    &--cta {
        display: flex;

        &--disconnect {
            justify-content: center;
        }

        &--title {
            @include worksans();
            @include font-size-lh(20, 23);
            color: $white;
            text-transform: uppercase;
            background-color: $navy-blue;
            min-width: 275px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 3px 6px rgba($black, 0.16);
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
                min-width: get-vw(275, $regofit-vw);
                height: get-vw(60, $regofit-vw);
                box-shadow: 0 get-vw(3, $regofit-vw) get-vw(6, $regofit-vw) rgba($black, 0.16);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
                min-width: get-vw(275, $medium-vw);
                height: get-vw(60, $medium-vw);
                box-shadow: 0 get-vw(3, $medium-vw) get-vw(6, $medium-vw) rgba($black, 0.16);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
                min-width: get-vw(412.5, $medium-vw);
                height: get-vw(90, $medium-vw);
                box-shadow: 0 get-vw(4.5, $medium-vw) get-vw(9, $medium-vw) rgba($black, 0.16);
            }
        }
    }

    &--history {
        &--cta {
            &--invoice {
                svg {
                    @include font-size-lh(40, 40);
                    @include mq(null, $w1440) {
                        @include font-size-vw(40, 40, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(40, 40, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(60, 60, $medium-vw);
                    }
                }
            }

            &--reorder {
                @include worksans();
                @include font-size-lh(20, 23);
                color: $cyan;
                text-align: center;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }
            }

            &--details {
                border: 3px solid $cyan;
                width: 100%;
                padding: 5px 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $cyan;
                @include mq(null, $w1440) {
                    padding: get-vw(5, $regofit-vw) get-vw(20, $regofit-vw);
                }
                @include mq(null, $w768) {
                    padding: get-vw(5, $medium-vw) get-vw(20, $medium-vw);
                }
                @include mq(null, $w375) {
                    padding: get-vw(7.5, $medium-vw) get-vw(30, $medium-vw);
                }
            }

            &--link {
                @include worksans();
                @include font-size-lh(20, 23);
                color: $white;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }

                &:hover {
                    color: $white;
                }
            }
        }
    }

    &--orders {
        width: 100%;
        box-shadow: 0 0 8px rgba($black, 0.16);
        position: relative;
        @include mq(null, $w1440) {
            box-shadow: 0 0 get-vw(8, $regofit-vw) rgba($black, 0.16);
        }
        @include mq(null, $w768) {
            box-shadow: 0 0 get-vw(8, $medium-vw) rgba($black, 0.16);
        }
        @include mq(null, $w375) {
            box-shadow: 0 0 get-vw(12, $medium-vw) rgba($black, 0.16);
        }

        &--header {
            background-color: $cyan;
            padding: 15px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include mq(null, $w1440) {
                padding: get-vw(15, $regofit-vw) get-vw(20, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: get-vw(15, $medium-vw) get-vw(20, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: get-vw(22.5, $medium-vw) get-vw(30, $medium-vw);
            }

            &--title {
                @include worksans(700);
                @include font-size-lh(25, 29);
                color: $white;
                @include mq(null, $w1440) {
                    @include font-size-vw(25, 29, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(25, 29, $medium-vw);
                    margin-right: get-vw(30, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(37.5, 43.5, $medium-vw);
                    margin-right: get-vw(45, $medium-vw);
                }
            }

            &--picto {
                svg {
                    @include font-size-lh(40, 40);
                    color: $white;
                    @include mq(null, $w1440) {
                        @include font-size-vw(40, 40, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(40, 40, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(60, 60, $medium-vw);
                    }
                }
            }
        }

        &--inner {
            padding: 15px;
            @include mq(null, $w1440) {
                padding: get-vw(15, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: get-vw(15, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: get-vw(22.5, $medium-vw);
            }
        }

        &--history,
        &--order-slip,
        &--discount {
            &.m {
                display: none;
                justify-content: space-between;
                margin: 0 -15px;
            }
            @include mq(null, $w768) {
                &.m {
                    display: flex;
                }

                &.d {
                    display: none;
                }
            }

            &--card {
                padding: 15px;
                margin: 15px;
                position: relative;
                @include mq(null, $w768) {
                    width: 50%;
                    box-shadow: 0 0 get-vw(8, $medium-vw) rgba($black, 0.16);
                    margin: get-vw(15, $medium-vw);
                }
                @include mq(null, $w375) {
                    width: 100%;
                    box-shadow: 0 0 get-vw(12, $medium-vw) rgba($black, 0.16);
                    margin: get-vw(22.5, $medium-vw);
                }

                &--line {
                    @include worksans();
                    @include font-size-lh(18, 21);
                    color: $navy-blue;
                    margin-top: 5px;
                    @include mq(null, $w768) {
                        @include font-size-vw(18, 21, $medium-vw);
                        margin-top: get-vw(5, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(36, 42, $medium-vw);
                        margin-top: get-vw(10, $medium-vw);
                    }

                    span {
                        @include worksans(600);
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &--ref {
                        @include worksans(600);
                    }

                    &--state {
                        color: $white;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 5px;
                        @include mq(null, $w768) {
                            @include font-size-vw(14, 16, $medium-vw);
                            padding: get-vw(5, $medium-vw) get-vw(10, $medium-vw);
                            border-radius: get-vw(5, $medium-vw);
                        }
                        @include mq(null, $w375) {
                            @include font-size-vw(28, 32, $medium-vw);
                            padding: get-vw(15, $medium-vw) get-vw(20, $medium-vw);
                            border-radius: get-vw(15, $medium-vw);
                        }
                    }
                }

                &--cta {
                    position: absolute;
                    top: 15px;
                    right: 15px;

                    &--invoice,
                    &--details,
                    &--reorder {
                        margin-bottom: 10px;
                        @include mq(null, $w768) {
                            margin-bottom: get-vw(10, $medium-vw);
                        }
                        @include mq(null, $w375) {
                            margin-bottom: get-vw(15, $medium-vw);
                        }

                        svg {
                            @include font-size-lh(40, 40);
                            color: $cyan;
                            @include mq(null, $w768) {
                                @include font-size-vw(20, 20, $medium-vw);
                            }
                            @include mq(null, $w375) {
                                @include font-size-vw(40, 40, $medium-vw);
                            }
                        }
                    }
                }
            }

            &--header,
            &--body {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }

            &--header {
                background-color: rgba($black, 0.16);
                align-items: center;
            }

            &--body {
                border-top: 1px solid rgba($black, 0.16);
                padding: 10px 0;
                @include mq(null, $w1440) {
                    padding: get-vw(10, $regofit-vw) 0;
                }
                @include mq(null, $w768) {
                    padding: get-vw(10, $medium-vw) 0;
                }
                @include mq(null, $w375) {
                    padding: get-vw(15, $medium-vw) 0;
                }

                &:first-child {
                    padding-top: 0;
                    border-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                &:nth-child(2n+1) {
                    background-color: rgba($cyan, 0.15);
                }
            }

            &--col {
                @include worksans();
                @include font-size-lh(18, 21);
                color: $navy-blue;
                text-align: center;
                width: calc(100% / 7);
                padding: 5px;
                margin: 5px;
                @include mq(null, $w1440) {
                    @include font-size-vw(18, 21, $regofit-vw);
                    padding: get-vw(5, $regofit-vw);
                    margin: get-vw(5, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(18, 21, $medium-vw);
                    padding: get-vw(5, $medium-vw);
                    margin: get-vw(5, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(27, 31.5, $medium-vw);
                    padding: get-vw(7.5, $medium-vw);
                    margin: get-vw(7.5, $medium-vw);
                }

                .account--orders--history--header &,
                .account--orders--order-slip--header &{
                    @include worksans(600);
                }

                .account--orders--order-slip & {
                    width: calc(100% / 4);
                }
            }

            &--state {
                @include font-size-lh(14, 16);
                color: $white;
                border-radius: 5px;
                @include mq(null, $w1440) {
                    @include font-size-vw(14, 16, $regofit-vw);
                    border-radius: get-vw(5, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(14, 16, $medium-vw);
                    border-radius: get-vw(5, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(21, 24, $medium-vw);
                    border-radius: get-vw(7.5, $medium-vw);
                }
            }
        }
    }

    &--addresses {
        &--items {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin: 0 -15px;
        }

        &--item {
            width: 33.333%;
            padding: 0 15px;
            margin-bottom: 15px;
            @include mq(null, $w768) {
                width: 50%;
                margin-bottom: get-vw(15, $regofit-vw);
            }
            @include mq(null, $w375) {
                width: 100%;
                margin-bottom: get-vw(22.5, $medium-vw);
            }
        }

        &--address {
            box-shadow: 0 0 8px rgba($black, 0.16);
            @include mq(null, $w1440) {
                box-shadow: 0 0 get-vw(8, $regofit-vw) rgba($black, 0.16);
            }
            @include mq(null, $w768) {
                box-shadow: 0 0 get-vw(8, $medium-vw) rgba($black, 0.16);
            }
            @include mq(null, $w375) {
                box-shadow: 0 0 get-vw(12, $medium-vw) rgba($black, 0.16);
            }

            &--body {
                padding: 15px;
                @include mq(null, $w1440) {
                    padding: get-vw(15, $regofit-vw);
                }
                @include mq(null, $w768) {
                    padding: get-vw(15, $medium-vw);
                }
                @include mq(null, $w375) {
                    padding: get-vw(22.5, $medium-vw);
                }
            }

            &--alias {
                @include worksans(600);
                @include font-size-lh(20, 23);
                color: $cyan;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }
            }

            &--content {
                @include worksans();
                @include font-size-lh(18, 21);
                color: $black;
                @include mq(null, $w1440) {
                    @include font-size-vw(18, 21, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(18, 21, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(24, 31.5, $medium-vw);
                }
            }

            &--footer {
                text-align: center;
                padding: 10px;
                @include mq(null, $w1440) {
                    padding: get-vw(10, $regofit-vw);
                }
                @include mq(null, $w768) {
                    padding: get-vw(10, $medium-vw);
                }
                @include mq(null, $w375) {
                    padding: get-vw(15, $medium-vw);
                }
            }

            hr {
                margin: 0;
            }

            &--edit,
            &--delete {
                @include poppins();
                @include font-size-lh(20, 30);
                color: $black;
                display: inline-block;
                padding: 0 5px;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 30, $regofit-vw);
                    padding: 0 get-vw(5, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 30, $medium-vw);
                    padding: 0 get-vw(5, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 45, $medium-vw);
                    padding: 0 get-vw(7.5, $medium-vw);
                }
            }

            &--delete {
                .picto-trash {
                    width: 20px;
                    height: 20px;
                    @include mq(null, $w1440) {
                        width: get-vw(20, $regofit-vw);
                        height: get-vw(20, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        width: get-vw(20, $medium-vw);
                        height: get-vw(20, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        width: get-vw(30, $medium-vw);
                        height: get-vw(30, $medium-vw);
                    }

                    .cls-1 {
                        fill: $black;
                    }
                }
            }
        }

        &--footer {
            margin-top: 40px;
            @include mq(null, $w1440) {
                margin-top: get-vw(40, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(40, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-top: get-vw(60, $medium-vw);
            }
        }

        &--add {
            @include worksans();
            @include font-size-lh(20, 23);
            color: $navy-blue;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }

            &:hover {
                color: $navy-blue;
            }
        }
    }
}