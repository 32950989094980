.push {
    &--presentation {
        padding: 100px 0;
        position: relative;
        z-index: 10;
        overflow: hidden;
        @include mq(null, $w1440) {
            padding: get-vw(100, $regofit-vw) 0;
        }
        @include mq(null, $w768) {
            padding: get-vw(100, $medium-vw) 0;
        }
        @include mq(null, $w375) {
            padding: get-vw(150, $medium-vw) 0;
        }

        &--informations {
            &--inner {
                text-align: center;
                max-width: 750px;
                margin: auto;
                @include mq(null, $w1440) {
                    max-width: get-vw(750, $regofit-vw);
                }
                @include mq(null, $w768) {
                    max-width: get-vw(750, $medium-vw);
                }
                @include mq(null, $w375) {
                    max-width: 100%;
                }
            }

            &--title,
            &--subtitle {
                @include raleway(900);
                text-transform: uppercase;
                margin: 0;
            }

            &--title {
                @include font-size-lh(22, 26);
                color: $cyan;
                letter-spacing: 3.07px;
                @include mq(null, $w1440) {
                    @include font-size-vw(22, 26, $regofit-vw);
                    letter-spacing: get-vw(3.07, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(22, 26, $medium-vw);
                    letter-spacing: get-vw(3.07, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(33, 39, $medium-vw);
                    letter-spacing: get-vw(4.605, $medium-vw);
                }
            }

            &--subtitle {
                @include font-size-lh(60, 70);
                color: $navy-blue;
                @include mq(null, $w1440) {
                    @include font-size-vw(60, 70, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(60, 70, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(90, 105, $medium-vw);
                }
            }

            &--text {
                margin-top: 15px;
                @include mq(null, $w1440) {
                    margin-top: get-vw(15, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-top: get-vw(15, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(22.5, $medium-vw);
                }

                p {
                    @include worksans();
                    @include font-size-lh(18, 21);
                    color: $navy-blue;
                    margin: 0;
                    @include mq(null, $w1440) {
                        @include font-size-vw(18, 21, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(18, 21, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(27, 31.5, $medium-vw);
                    }
                }
            }

            &--cta {
                display: flex;
                justify-content: center;
                margin-top: 28px;
                @include mq(null, $w1440) {
                    margin-top: get-vw(28, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-top: get-vw(28, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(42, $medium-vw);
                }

                &--link {
                    padding: 15px 50px;
                    background-color: $cyan;
                    border-radius: 10px;
                    cursor: pointer;
                    @include mq(null, $w1440) {
                        padding: get-vw(15, $regofit-vw) get-vw(50, $regofit-vw);
                        border-radius: get-vw(10, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        padding: get-vw(15, $medium-vw) get-vw(50, $medium-vw);
                        border-radius: get-vw(10, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        padding: get-vw(22.5, $medium-vw) get-vw(75, $medium-vw);
                        border-radius: get-vw(15, $medium-vw);
                    }
                }

                &--title {
                    @include raleway(900);
                    @include font-size-lh(22, 26);
                    color: $white;
                    text-transform: uppercase;
                    letter-spacing: 3.07px;
                    margin: 0;
                    @include mq(null, $w1440) {
                        @include font-size-vw(22, 26, $regofit-vw);
                        letter-spacing: get-vw(3.07, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(22, 26, $medium-vw);
                        letter-spacing: get-vw(3.07, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(33, 39, $medium-vw);
                        letter-spacing: get-vw(4.605, $medium-vw);
                    }
                }
            }
        }

        &--use {
            margin-top: 155px;
            position: relative;
            @include mq(null, $w1440) {
                margin-top: get-vw(155, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(155, $medium-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(232.5, $medium-vw);
            }

            &--items {
                background-color: $navy-blue;

                &--inner {
                    display: flex;
                    justify-content: center;
                    @include mq(null, $w768) {
                        flex-direction: column;
                    }
                }
            }

            &--item {
                padding-top: 100px;
                padding-bottom: 85px;
                width: 50%;
                position: relative;
                @include mq(null, $w1440) {
                    padding-top: get-vw(100, $regofit-vw);
                    padding-bottom: get-vw(85, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: 100%;
                }
            }

            &--titles {
                position: relative;
            }

            &--picto {
                position: absolute;

                &--cold-hot {
                    position: absolute;
                    top: -30px;
                    bottom: -30px;
                    left: 50%;
                    z-index: 2;
                    transform: translate(-50%, 0%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 600px;
                    @include mq(null, $w1440) {
                        top: get-vw(-30, $regofit-vw);
                        bottom: get-vw(-30, $regofit-vw);
                        width: get-vw(600);
                    }
                    @include mq(null, $w768) {
                        top: get-vw(-30, $medium-vw);
                        bottom: get-vw(-30, $medium-vw);
                        transform: translateX(-50%) rotate(90deg);
                        width: get-vw(400, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        top: get-vw(-45, $medium-vw);
                        bottom: get-vw(-45, $medium-vw);
                        transform: translateX(-50%) rotate(90deg);
                        width: get-vw(600, $medium-vw);
                    }
                }
            }

            &--title,
            &--subtitle {
                text-transform: uppercase;
                margin: 0;
                @include mq(null, $w768) {
                    text-align: center;
                }
            }

            &--title {
                @include raleway(500);
                @include font-size-lh(22, 26);
                letter-spacing: 3.07px;
                @include mq(null, $w1440) {
                    @include font-size-vw(22, 26, $regofit-vw);
                    letter-spacing: get-vw(3.07, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(22, 26, $medium-vw);
                    letter-spacing: get-vw(3.07, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(33, 39, $medium-vw);
                    letter-spacing: get-vw(4.605, $medium-vw);
                }
            }

            &--subtitle {
                @include raleway(900);
                @include font-size-lh(60, 70);
                letter-spacing: 3.07px;
                margin-top: -5px;
                @include mq(null, $w1440) {
                    @include font-size-vw(60, 70, $regofit-vw);
                    letter-spacing: get-vw(3.07, $regofit-vw);
                    margin-top: get-vw(-5, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(60, 70, $medium-vw);
                    letter-spacing: get-vw(3.07, $medium-vw);
                    margin-top: get-vw(-5, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(90, 105, $medium-vw);
                    letter-spacing: get-vw(4.605, $medium-vw);
                    margin-top: get-vw(-7.5, $medium-vw);
                }
            }

            &--text {
                margin-top: 10px;
                @include mq(null, $w1440) {
                    margin-top: get-vw(10, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-top: get-vw(10, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(15, $medium-vw);
                }

                p {
                    @include worksans();
                    @include font-size-lh(20, 24);
                    color: $white;
                    margin: 0;
                    @include mq(null, $w1440) {
                        @include font-size-vw(20, 24, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(20, 24, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(30, 36, $medium-vw);
                    }
                }
            }

            &--plots {
                position: absolute;
                pointer-events: none;
            }

            &--image {
                position: absolute;
                top: -30px;
                bottom: -30px;
                left: 50%;
                z-index: 2;
                transform: translate(-50%, 0%);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 585px;
                @include mq(null, $w1440) {
                    top: get-vw(-30, $regofit-vw);
                    bottom: get-vw(-30, $regofit-vw);
                    width: get-vw(585);
                }
                @include mq(null, $w768) {
                    top: get-vw(-30, $medium-vw);
                    bottom: get-vw(-30, $medium-vw);
                    width: get-vw(385, $medium-vw);
                }
                @include mq(null, $w375) {
                    top: get-vw(-45, $medium-vw);
                    bottom: get-vw(-45, $medium-vw);
                    width: get-vw(577.5, $medium-vw);
                }

                img {
                    width: auto;
                }
            }

            &--hot {
                padding-left: 21%;
                @include mq(null, $w1440) {
                    padding-left: 15vw;
                }
                @include mq(null, $w768) {
                    padding: 30vw 0 10vw 0;
                }
                @include mq(null, $w375) {
                    padding: 45vw 0 15vw 0;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 50vw;
                    height: 14px;
                    background-color: $red;
                    @include mq(null, $w1440) {
                        height: get-vw(14, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        height: get-vw(14, $medium-vw);
                        width: 100vw;
                        margin-left: -20px;
                    }
                    @include mq(null, $w768) {
                        height: get-vw(21, $medium-vw);
                    }
                }

                &--title,
                &--subtitle {
                    color: $red;
                }

                &--subtitle {
                    margin-left: 15px;
                    @include mq(null, $w1440) {
                        margin-left: get-vw(15, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        margin-left: 0;
                    }
                }

                &--text {
                    margin-left: 30px;
                    @include mq(null, $w1440) {
                        margin-left: get-vw(30, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        text-align: center;
                        margin-left: 0;
                    }
                }

                &--picto {
                    top: -8px;
                    right: get-vw(90);
                    @include mq(null, $w1440) {
                        top: get-vw(-8, $regofit-vw);
                        right: get-vw(160, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        top: get-vw(-8, $medium-vw);
                        right: get-vw(160, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        top: get-vw(-12, $medium-vw);
                        right: get-vw(40, $medium-vw);
                    }

                    .picto-fire {
                        width: 66px;
                        height: 88px;
                        @include mq(null, $w1440) {
                            width: get-vw(66, $regofit-vw);
                            height: get-vw(88, $regofit-vw);
                        }
                        @include mq(null, $w768) {
                            width: get-vw(66, $medium-vw);
                            height: get-vw(88, $medium-vw);
                        }
                        @include mq(null, $w375) {
                            width: get-vw(99, $medium-vw);
                            height: get-vw(132, $medium-vw);
                        }
                    }
                }

                &--plots {
                    left: -482px;
                    top: -110px;
                    @include mq(null, $w1440) {
                        left: get-vw(-482, $regofit-vw);
                        top: get-vw(-110, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        left: get-vw(-482, $medium-vw);
                        top: get-vw(-110, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        left: get-vw(-723, $medium-vw);
                        top: get-vw(-165, $medium-vw);
                    }
                }
            }

            &--cold {
                text-align: right;
                padding-right: 21%;
                z-index: 1;
                @include mq(null, $w1440) {
                    padding-right: 15vw;
                }
                @include mq(null, $w768) {
                    padding: 10vw 0 30vw 0;
                }
                @include mq(null, $w375) {
                    padding: 15vw 0 45vw 0;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 50vw;
                    height: 14px;
                    background-color: $blue;
                    @include mq(null, $w1440) {
                        height: get-vw(14, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        height: get-vw(14, $medium-vw);
                        width: 100vw;
                        margin-right: -20px;
                        top: 0;
                    }
                    @include mq(null, $w375) {
                        height: get-vw(21, $medium-vw);
                    }
                }

                &--title,
                &--subtitle {
                    color: $blue;
                }

                &--subtitle {
                    margin-right: 15px;
                    @include mq(null, $w1440) {
                        margin-right: get-vw(15, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        margin-right: get-vw(15, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        margin-right: get-vw(22.5, $medium-vw);
                    }
                }

                &--text {
                    margin-right: 30px;
                    @include mq(null, $w1440) {
                        margin-right: get-vw(30, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        text-align: center;
                        margin-right: 0;
                    }
                }

                &--picto {
                    top: -32px;
                    left: get-vw(130);
                    @include mq(null, $w1440) {
                        top: get-vw(-32, $regofit-vw);
                        left: get-vw(200, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        top: get-vw(-32, $medium-vw);
                        left: get-vw(180, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        top: get-vw(-48, $medium-vw);
                        left: get-vw(60, $medium-vw);
                    }

                    .picto-snow {
                        width: 65px;
                        height: 72px;
                        @include mq(null, $w1440) {
                            width: get-vw(65, $regofit-vw);
                            height: get-vw(72, $regofit-vw);
                        }
                        @include mq(null, $w768) {
                            width: get-vw(65, $medium-vw);
                            height: get-vw(72, $medium-vw);
                        }
                        @include mq(null, $w375) {
                            width: get-vw(97.5, $medium-vw);
                            height: get-vw(108, $medium-vw);
                        }
                    }
                }

                &--plots {
                    right: -366px;
                    top: -120px;
                    @include mq(null, $w1440) {
                        right: get-vw(-366, $regofit-vw);
                        top: get-vw(-120, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        right: get-vw(-366, $medium-vw);
                        top: get-vw(-120, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        right: get-vw(-549, $medium-vw);
                        top: get-vw(-180, $medium-vw);
                    }
                }
            }
        }
    }
}