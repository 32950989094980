.push {
    &--features {
        margin-top: get-vw(55);
        @include mq(null, $w768) {
            margin-top: get-vw(55, $medium-vw);
        }

        &--items {
            display: grid;
            grid-template-columns: 43.75% 1fr 1fr;
            @include mq(null, $w768) {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 0.5fr 0.5fr;
            }
        }

        &--item {
            position: relative;

            &--0 {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 3;
                @include mq(null, $w768) {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }
            }

            &--1,
            &--3 {
                grid-column-start: 2;
                grid-column-end: 3;
                @include mq(null, $w768) {
                    grid-column-start: 1;
                    grid-column-end: 2;
                }
            }

            &--2,
            &--4 {
                grid-column-start: 3;
                grid-column-end: 4;
                @include mq(null, $w768) {
                    grid-column-start: 2;
                    grid-column-end: 3;
                }
            }

            &--1,
            &--2 {
                grid-row-start: 1;
                grid-row-end: 2;
                @include mq(null, $w768) {
                    grid-row-start: 2;
                    grid-row-end: 3;
                }
            }

            &--3,
            &--4 {
                grid-row-start: 2;
                grid-row-end: 3;
                @include mq(null, $w768) {
                    grid-row-start: 3;
                    grid-row-end: 4;
                }
            }

            &:hover {
                .push--features--text {
                    opacity: 1;
                }
            }
        }

        &--content {
            position: absolute;
            top: 0;
            right: 30px;
            width: 69.05%;
            padding: get-vw(40) 0;
            @include mq(null, $w768) {
                $space: get-vw(30, $medium-vw);
                right: #{$space};
                padding: get-vw(40, $medium-vw) 0;

                .push--features--item:not(.push--features--item--0) & {
                    width: calc(100% - 2 * #{$space});
                }
            }
        }

        &--image {
            position: relative;
            width: 100%;
            height: 100%;

            &:after {
                content: '';
                position: absolute;
                background-color: rgba($navy-blue, 0.5);
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            img {
                object-fit: cover;
                object-position: 50% 50%;
                width: 100%;
                height: 100%;
            }
        }

        &--title {
            @include raleway(900);
            @include font-size-vw(60, 70);
            color: $cyan;
            text-align: right;
            text-transform: uppercase;
            margin: 0;
            @include mq(null, $w768) {
                @include font-size-vw(50, 58, $medium-vw);
            }
        }

        &--text {
            position: absolute;
            top: get-vw(161);
            right: get-vw(18);
            z-index: 5;
            background-color: rgba($navy-blue, 0.88);
            width: 100%;
            padding: get-vw(33) get-vw(30) get-vw(33) get-vw(23);
            opacity: 0;
            transition: all 0.3s;
            @include mq(null, $w768) {
                top: get-vw(150, $medium-vw);
                right: get-vw(18, $medium-vw);
                padding: get-vw(33, $medium-vw) get-vw(30, $medium-vw) get-vw(33, $medium-vw) get-vw(23, $medium-vw);
            }

            .push--features--item--0 & {
                top: get-vw(195);
                @include mq(null, $w768) {
                    top: get-vw(150, $medium-vw);
                }
            }

            p {
                @include worksans();
                @include font-size-vw(18, 21);
                color: $white;
                text-align: right;
                margin: 0;
                @include mq(null, $w768) {
                    @include font-size-vw(18, 21, $medium-vw);
                }
            }
        }

        &--cta {
            margin-right: get-vw(-30);
            @include mq(null, $w768) {
                margin-right: get-vw(-30, $medium-vw);
            }

            &--plus {
                @include worksans(200);
                @include font-size-vw(150, 90);
                color: $cyan;
                text-align: right;
                margin: 0;
                @include mq(null, $w768) {
                    @include font-size-vw(150, 90, $medium-vw);
                }

                .push--features--item--0 & {
                    @include font-size-vw(150, 368);
                    @include mq(null, $w768) {
                        @include font-size-vw(150, 368, $medium-vw);
                    }
                }
            }
        }
    }
}