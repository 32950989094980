.push {
    &--product {
        $widthImages: get-vw(550);
        $widthContent: get-vw(524);
        $widthSpace: #{$widthImages} + #{$widthContent};
        $widthImages768: get-vw(293, $medium-vw);
        $heightImages768: get-vw(298, $medium-vw);
        $widthContent768: get-vw(279, $medium-vw);
        $widthSpace768: #{$widthImages768} + #{$widthContent768};
        padding: 70px 0;
        position: relative;
        @include mq(null, $w1440) {
            padding: get-vw(70, $regofit-vw) 0;
        }
        @include mq(null, $w768) {
            padding: get-vw(70, $medium-vw) 0;
        }

        &--favicon {
            position: absolute;
            top: 50%;
            left: -425px;
            transform: translateY(-50%) rotate(90deg);
            @include mq(null, $w1440) {
                left: get-vw(-425, $regofit-vw);
            }
            @include mq(null, $w768) {
                left: get-vw(-425, $medium-vw);
            }

            .favicon {
                width: 528px;
                height: 459px;
                @include mq(null, $w1440) {
                    width: get-vw(528, $regofit-vw);
                    height: get-vw(459, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: get-vw(528, $medium-vw);
                    height: get-vw(459, $medium-vw);
                }

                .cls-1 {
                    opacity: 0.04;
                }

                .cls-2 {
                    fill: $navy-blue;
                }
            }
        }

        &--inner {
            display: flex;
            align-items: flex-end;
            position: relative;
            @include mq(null, $w768) {
                align-items: center;
            }
            @include mq(null, $w375) {
                flex-wrap: wrap;
            }
        }

        &--images {
            flex: 1 1 #{$widthImages};
            padding: 0 65px;
            @include mq(null, $w1440) {
                padding: 0 get-vw(65, $regofit-vw);
            }
            @include mq(null, $w768) {
                flex: 1 1 #{$widthImages768};
                padding: 0 get-vw(30, $medium-vw);
            }
            @include mq(null, $w375) {
                flex: 1 0 100%;
            }

            &--slide {
                border-radius: 34px;
                box-shadow: 0 0 40px rgba($shadow, 0.1);
                width: 550px;
                height: 565px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                @include mq(null, $w1440) {
                    border-radius: get-vw(34, $regofit-vw);
                    box-shadow: 0 0 get-vw(40, $regofit-vw) rgba($shadow, 0.1);
                    width: get-vw(550, $regofit-vw);
                    height: get-vw(565, $regofit-vw);
                }
                @include mq(null, $w768) {
                    border-radius: get-vw(34, $medium-vw);
                    box-shadow: 0 0 get-vw(40, $medium-vw) rgba($shadow, 0.1);
                    width: #{$widthImages768};
                    height: #{$heightImages768};
                }
                @include mq(null, $w375) {
                    width: get-vw(640, $medium-vw);
                    height: get-vw(660, $medium-vw);
                }

                &--image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 99 !important;
                }

                &--dots {
                    margin-top: 15px;
                    @include mq(null, $w1440) {
                        margin-top: get-vw(15, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        margin-top: get-vw(15, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        margin-top: get-vw(30, $medium-vw);
                    }

                    .slick-dots {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0;
                        padding: 0;

                        li {
                            margin: 0 6px;
                            @include mq(null, $w1440) {
                                margin: 0 get-vw(6, $regofit-vw);
                            }
                            @include mq(null, $w768) {
                                margin: 0 get-vw(6, $medium-vw);
                            }
                            @include mq(null, $w375) {
                                margin: 0 get-vw(12, $medium-vw);
                            }

                            button {
                                text-indent: -9999px;
                                width: 13px;
                                height: 13px;
                                background-color: $navy-blue;
                                border: 2px solid $navy-blue;
                                border-radius: 50%;
                                transition: all 0.3s;
                                appearance: none;
                                margin: 0;
                                padding: 0;
                                outline: none;
                                @include mq(null, $w1440) {
                                    width: get-vw(13, $regofit-vw);
                                    height: get-vw(13, $regofit-vw);
                                }
                                @include mq(null, $w768) {
                                    width: get-vw(13, $medium-vw);
                                    height: get-vw(13, $medium-vw);
                                }
                                @include mq(null, $w375) {
                                    width: get-vw(26, $medium-vw);
                                    height: get-vw(26, $medium-vw);
                                }
                            }

                            &.slick-active {
                                button {
                                    background-color: $white;
                                }
                            }
                        }
                    }
                }

                .slick-list {
                    width: 100%;
                }

                .slick-track {
                    display: flex;
                }
            }
        }

        &--space {
            flex: 1 1 calc(100% - #{$widthSpace});
            @include mq(null, $w768) {
                display: none;
            }
        }

        &--content {
            flex: 1 0 #{$widthContent};
            padding-bottom: 40px;
            @include mq(null, $w1440) {
                padding-bottom: get-vw(40, $regofit-vw);
            }
            @include mq(null, $w768) {
                flex: 1 0 #{$widthContent768};
                padding-bottom: get-vw(40, $medium-vw);
            }
            @include mq(null, $w375) {
                flex: 1 0 100%;
            }
        }

        &--characteristics {
            &--item {
                border-bottom: 1px solid $grey;
                padding: 10px 0;
                @include mq(null, $w1440) {
                    padding: get-vw(10, $regofit-vw) 0;
                }
                @include mq(null, $w768) {
                    padding: get-vw(10, $medium-vw) 0;
                }
                @include mq(null, $w375) {
                    padding: get-vw(20, $medium-vw) 0;
                }

                p {
                    @include worksans();
                    @include font-size-lh(18, 21);
                    color: $navy-blue;
                    margin: 0;
                    @include mq(null, $w1440) {
                        @include font-size-vw(18, 21, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(16, 18, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(32, 36, $medium-vw);
                    }

                    span {
                        @include worksans(700);
                    }
                }
            }
        }

        &--text {
            border-bottom: 1px solid $grey;
            padding: 10px 0;
            @include mq(null, $w1440) {
                padding: get-vw(10, $regofit-vw) 0;
            }
            @include mq(null, $w768) {
                padding: get-vw(10, $medium-vw) 0;
            }
            @include mq(null, $w375) {
                padding: get-vw(20, $medium-vw) 0;
            }

            p {
                @include worksans();
                @include font-size-lh(18, 21);
                color: $navy-blue;
                margin: 0;
                @include mq(null, $w1440) {
                    @include font-size-vw(18, 21, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(16, 18, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(32, 36, $medium-vw);
                }
            }
        }

        &--attributes {
            display: flex;
            align-items: center;
            margin-top: 35px;
            @include mq(null, $w1440) {
                margin-top: get-vw(35, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(35, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-top: get-vw(70, $medium-vw);
            }

            &--label {
                @include worksans(700);
                @include font-size-lh(20, 24);
                color: $black;
                margin: 0;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 24, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(18, 21.6, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(36, 43, $medium-vw);
                }
            }

            &--items {
                display: flex;
                align-items: center;
                margin-left: 12px;
                @include mq(null, $w1440) {
                    margin-left: get-vw(12, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-left: get-vw(12, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-left: get-vw(24, $medium-vw);
                }
            }

            &--item {
                width: 22px;
                height: 22px;
                margin: 0 12px;
                position: relative;
                @include mq(null, $w1440) {
                    width: get-vw(22, $regofit-vw);
                    height: get-vw(22, $regofit-vw);
                    margin: 0 get-vw(12, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: get-vw(22, $medium-vw);
                    height: get-vw(22, $medium-vw);
                    margin: 0 get-vw(12, $medium-vw);
                }
                @include mq(null, $w375) {
                    width: get-vw(44, $medium-vw);
                    height: get-vw(44, $medium-vw);
                    margin: 0 get-vw(24, $medium-vw);
                }

                &--input {
                    &--color {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        width: 22px;
                        height: 22px;
                        @include mq(null, $w1440) {
                            width: get-vw(22, $regofit-vw);
                            height: get-vw(22, $regofit-vw);
                        }
                        @include mq(null, $w768) {
                            width: get-vw(22, $medium-vw);
                            height: get-vw(22, $medium-vw);
                        }
                        @include mq(null, $w375) {
                            width: get-vw(44, $medium-vw);
                            height: get-vw(44, $medium-vw);
                        }
                    }

                    &--radio {
                        position: absolute;
                        top: 0;
                        cursor: pointer;
                        opacity: 0;
                        width: 100%;
                        height: 100%;

                        &:checked + span {
                            border-color: $navy-blue;
                        }
                    }
                }

                &--color {
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    display: inline-block;
                    transition: all 0.3s;
                    @include mq(null, $w1440) {
                        width: get-vw(22, $regofit-vw);
                        height: get-vw(22, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        width: get-vw(22, $medium-vw);
                        height: get-vw(22, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        width: get-vw(44, $medium-vw);
                        height: get-vw(44, $medium-vw);
                    }

                    &[data-name="Blanc"] {
                        border: 1px solid $black;
                    }
                }

                &--radio {
                    position: relative;
                    margin-right: 10px;
                    margin-bottom: 0;
                    cursor: pointer;
                    @include mq(null, $w1440) {
                        margin-right: get-vw(10, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        margin-right: get-vw(10, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        margin-right: get-vw(20, $medium-vw);
                    }

                    span {
                        @include worksans();
                        @include font-size-lh(18, 21);
                        color: $navy-blue;
                        display: inline-block;
                        background-color: $white;
                        border: 2px solid rgba($navy-blue, 0.09);
                        padding: 5px 15px;
                        @include mq(null, $w1440) {
                            @include font-size-vw(18, 21, $regofit-vw);
                            padding: get-vw(5, $regofit-vw) get-vw(15, $regofit-vw);
                        }
                        @include mq(null, $w768) {
                            @include font-size-vw(16, 18, $medium-vw);
                            padding: get-vw(5, $medium-vw) get-vw(15, $medium-vw);
                        }
                        @include mq(null, $w375) {
                            @include font-size-vw(32, 36, $medium-vw);
                            padding: get-vw(10, $medium-vw) get-vw(30, $medium-vw);
                        }
                    }
                }
            }
        }

        &--quantities-price {
            display: flex;
            align-items: center;
            margin-top: 15px;
            @include mq(null, $w1440) {
                margin-top: get-vw(15, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(15, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-top: get-vw(30, $medium-vw);
            }
        }

        &--quantities {
            display: flex;
            align-items: center;
            margin-right: 22px;
            @include mq(null, $w1440) {
                margin-right: get-vw(22, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-right: get-vw(22, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-right: get-vw(44, $medium-vw);
            }

            &--quantity {
                @include worksans(500);
                @include font-size-lh(22, 22);
                color: $navy-blue !important;
                text-align: center;
                margin: 0 43px;
                padding: 0 !important;
                width: 37px !important;
                height: 38px !important;
                border: 1px solid $navy-blue !important;
                border-radius: 10px !important;
                float: none !important;
                @include mq(null, $w1440) {
                    @include font-size-vw(22, 22, $regofit-vw);
                    margin: 0 get-vw(43, $regofit-vw);
                    width: get-vw(37, $regofit-vw) !important;
                    height: get-vw(38, $regofit-vw) !important;
                    border-radius: get-vw(10, $regofit-vw) !important;
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 20, $medium-vw);
                    margin: 0 get-vw(43, $medium-vw);
                    width: get-vw(37, $medium-vw) !important;
                    height: get-vw(38, $medium-vw) !important;
                    border-radius: get-vw(10, $medium-vw) !important;
                }
                @include mq(null, $w375) {
                    @include font-size-vw(40, 40, $medium-vw);
                    margin: 0 get-vw(86, $medium-vw);
                    width: get-vw(74, $medium-vw) !important;
                    height: get-vw(76, $medium-vw) !important;
                    border-radius: get-vw(20, $medium-vw) !important;
                }
            }

            .bootstrap-touchspin {
                float: none;
                box-shadow: none;

                .input-group-btn-vertical {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    float: none;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: center;

                    i {
                        position: relative;
                        top: -1px;
                        left: 0;

                        &:after {
                            @include worksans(500);
                            @include font-size-lh(22, 22);
                            color: $white;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            @include mq(null, $w1440) {
                                @include font-size-vw(22, 22, $regofit-vw);
                            }
                            @include mq(null, $w768) {
                                @include font-size-vw(20, 20, $medium-vw);
                            }
                            @include mq(null, $w375) {
                                @include font-size-vw(40, 40, $medium-vw);
                            }
                        }

                        &.touchspin-up {
                            &:after {
                                content: '+';
                            }
                        }

                        &.touchspin-down {
                            &:after {
                                content: '-';
                            }
                        }
                    }
                }

                .btn-touchspin {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 37px;
                    height: 38px;
                    background-color: $navy-blue;
                    border: 1px solid $navy-blue;
                    border-radius: 10px;
                    padding: 0;
                    margin: 0;
                    @include mq(null, $w1440) {
                        width: get-vw(37, $regofit-vw);
                        height: get-vw(38, $regofit-vw);
                        border-radius: get-vw(10, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        width: get-vw(37, $medium-vw);
                        height: get-vw(38, $medium-vw);
                        border-radius: get-vw(10, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        width: get-vw(74, $medium-vw);
                        height: get-vw(76, $medium-vw);
                        border-radius: get-vw(20, $medium-vw);
                    }

                    &-up {
                        &:after {
                            content: '+';
                        }
                    }

                    &-down {
                        &:after {
                            content: '-';
                        }
                    }
                }
            }
        }

        &--price {
            @include worksans(700);
            @include font-size-lh(58, 68);
            color: $black;
            margin: 0;
            @include mq(null, $w1440) {
                @include font-size-vw(58, 68, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(50, 58, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(100, 116, $medium-vw);
            }
        }

        &--add-to-cart {
            margin-top: 30px;
            @include mq(null, $w1440) {
                margin-top: get-vw(30, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(30, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-top: get-vw(60, $medium-vw);
            }

            &--button {
                @include raleway(900);
                @include font-size-lh(22, 26);
                color: $white;
                text-transform: uppercase;
                letter-spacing: 3.07px;
                width: 266px;
                height: 60px;
                border: none;
                border-radius: 10px;
                background-color: $cyan;
                @include mq(null, $w1440) {
                    @include font-size-vw(22, 26, $regofit-vw);
                    letter-spacing: get-vw(3.07, $regofit-vw);
                    width: get-vw(266, $regofit-vw);
                    height: get-vw(60, $regofit-vw);
                    border-radius: get-vw(10, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(19, 22, $medium-vw);
                    letter-spacing: get-vw(3.07, $medium-vw);
                    width: get-vw(230, $medium-vw);
                    height: get-vw(50, $medium-vw);
                    border-radius: get-vw(10, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(38, 44, $medium-vw);
                    width: get-vw(460, $medium-vw);
                    height: get-vw(100, $medium-vw);
                    border-radius: get-vw(20, $medium-vw);
                }
            }
        }
    }
}