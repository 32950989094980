#footer {
    padding-top: 0
}

.footer {
    &--inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 120px;
        position: relative;
        overflow: hidden;
        @include mq(null, $w1440) {
            padding-top: get-vw(30, $regofit-vw);
            padding-bottom: get-vw(120, $regofit-vw);
        }
        @include mq(null, $w768) {
            padding-top: get-vw(30, $medium-vw);
            padding-bottom: get-vw(120, $medium-vw);
        }
        @include mq(null, $w375) {
            padding-top: get-vw(60, $medium-vw);
            padding-bottom: get-vw(240, $medium-vw);
        }
    }

    &--logo {
        &--link {
            display: flex;
        }

        .logo-regofit {
            width: 210px;
            height: 48px;
            @include mq(null, $w1440) {
                width: get-vw(210, $regofit-vw);
                height: get-vw(48, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: get-vw(210, $medium-vw);
                height: get-vw(48, $medium-vw);
            }
            @include mq(null, $w375) {
                width: get-vw(420, $medium-vw);
                height: get-vw(96, $medium-vw);
            }
        }
    }

    &--links {
        padding-top: 8px;
        padding-bottom: 13px;
        border-bottom: 1px solid $navy-blue;
        @include mq(null, $w1440) {
            padding-top: get-vw(8, $regofit-vw);
            padding-bottom: get-vw(13, $regofit-vw);
        }
        @include mq(null, $w768) {
            padding-top: get-vw(8, $medium-vw);
            padding-bottom: get-vw(13, $medium-vw);
        }
        @include mq(null, $w375) {
            padding-top: get-vw(16, $medium-vw);
            padding-bottom: get-vw(26, $medium-vw);
        }
    }

    &--sub-menu {
        display: flex;
        justify-content: center;
        align-self: center;
        margin: 0;
        padding: 0;
        @include mq(null, $w768) {
            flex-direction: column;
            text-align: center;
        }

        &--li {
            position: relative;
            padding: 0 5px;
            @include mq(null, $w1440) {
                padding: 0 get-vw(5, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: 0 get-vw(5, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: 0 get-vw(10, $medium-vw);
            }

            &:not(:first-child) {
                &:before {
                    @include worksans();
                    @include font-size-lh(16, 40);
                    color: $navy-blue;
                    content: 'I';
                    position: absolute;
                    top: 0;
                    left: -3px;
                    @include mq(null, $w1440) {
                        @include font-size-vw(16, 40, $regofit-vw);
                        left: get-vw(-3, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        display: none;
                    }
                }
            }
        }

        &--title {
            @include worksans();
            @include font-size-lh(16, 40);
            color: $navy-blue;
            @include mq(null, $w1440) {
                @include font-size-vw(16, 40, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(16, 40, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 60, $medium-vw);
            }
        }
    }

    &--image {
        margin-top: 20px;
        @include mq(null, $w1440) {
            margin-top: get-vw(20, $regofit-vw);
        }
        @include mq(null, $w768) {
            margin-top: get-vw(20, $medium-vw);
        }
        @include mq(null, $w375) {
            margin-top: get-vw(40, $medium-vw);
        }

        img {
            width: 113px;
            height: 111px;
            @include mq(null, $w1440) {
                width: get-vw(113, $regofit-vw);
                height: get-vw(111, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: get-vw(113, $medium-vw);
                height: get-vw(111, $medium-vw);
            }
            @include mq(null, $w375) {
                width: get-vw(226, $medium-vw);
                height: get-vw(222, $medium-vw);
            }
        }
    }

    &--favicon {
        position: absolute;
        bottom: -317px;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 459px;
        height: 528px;
        @include mq(null, $w1440) {
            bottom: get-vw(-317, $regofit-vw);
            width: get-vw(459, $regofit-vw);
            height: get-vw(528, $regofit-vw);
        }
        @include mq(null, $w768) {
            bottom: get-vw(-317, $medium-vw);
            width: get-vw(459, $medium-vw);
            height: get-vw(528, $medium-vw);
        }
        @include mq(null, $w375) {
            bottom: get-vw(-634, $medium-vw);
            width: get-vw(918, $medium-vw);
            height: get-vw(1056, $medium-vw);
        }

        .favicon {
            width: 459px;
            height: 528px;
            @include mq(null, $w1440) {
                width: get-vw(459, $regofit-vw);
                height: get-vw(528, $regofit-vw);
            }
            @include mq(null, $w768) {
                width: get-vw(459, $medium-vw);
                height: get-vw(528, $medium-vw);
            }
            @include mq(null, $w375) {
                width: get-vw(918, $medium-vw);
                height: get-vw(1056, $medium-vw);
            }

            .cls-1 {
                opacity: 1;
            }

            .cls-2 {
                fill: $cyan;
            }
        }
    }
}