body#checkout {
    section.checkout-step {
        box-shadow: none;
        padding: 0;
        border: none;

        small {
            color: $navy-blue
        }

        .address-item.selected {
            border-color: $cyan;
        }

        .address-alias {
            @include worksans(600);
            @include font-size-lh(20, 23);
            color: $cyan;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }
        }

        .address {
            @include worksans();
            @include font-size-lh(18, 21);
            color: $black;
            @include mq(null, $w1440) {
                @include font-size-vw(18, 21, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(18, 21, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(27, 31.5, $medium-vw);
            }
        }

        .delete-address, .edit-address {
            @include poppins(400);
            @include font-size-lh(20, 30);
            color: $black !important;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 30, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 30, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 45, $medium-vw);
            }
        }

        .delete-address {
            .picto-trash {
                width: 20px;
                height: 20px;
                @include mq(null, $w1440) {
                    width: get-vw(20, $regofit-vw);
                    height: get-vw(20, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: get-vw(20, $medium-vw);
                    height: get-vw(20, $medium-vw);
                }
                @include mq(null, $w375) {
                    width: get-vw(30, $medium-vw);
                    height: get-vw(30, $medium-vw);
                }

                .cls-1 {
                    fill: $black;
                }
            }
        }

        .add-address a {
            @include worksans();
            @include font-size-lh(20, 23);
            color: $navy-blue;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }
        }

        .carrier-name,
        .carrier-delay,
        .carrier-price {
            @include worksans();
            @include font-size-lh(20, 23);
            color: $navy-blue;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }
        }

        .carrier-name {
            @include worksans(600);
        }

        .order-options label {
            @include worksans();
            @include font-size-lh(20, 23);
            color: $navy-blue;
            text-align: left;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }
        }

        .delivery-options {
            > .row {
                margin-left: 0;
                margin-right: 0;
            }
        }

        #delivery textarea {
            @include worksans(700);
            @include font-size-lh(20, 23);
            color: $black;
            width: 100%;
            border: 2px solid rgba($border, 0.09);
            transition: all 0.3s;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }
        }

        .payment-options {
            .additional-information {
                section p {
                    @include worksans();
                    @include font-size-lh(20, 23);
                    color: $navy-blue;
                    @include mq(null, $w1440) {
                        @include font-size-vw(20, 23, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(20, 23, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(30, 34.5, $medium-vw);
                    }
                }
            }
        }

        .payment-option {
            display: flex;
            align-items: center;

            label {
                @include worksans();
                @include font-size-lh(20, 23);
                color: $black;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }
            }
        }
    }

    .condition-label {
        label {

        }
    }

    #footer {
        box-shadow: none;
    }
}

.custom-radio {
    border-color: rgba($black, 0.16);

    input[type="radio"]:checked + span {
        background-color: $cyan;
    }
}

.page {
    &--cart {
        &--inner {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @include mq(null, $w768) {
                flex-wrap: wrap;
            }
        }

        &--footer {
            margin-top: 50px;
            @include mq(null, $w1440) {
                margin-top: get-vw(50, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(50, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-top: get-vw(75, $medium-vw);
            }
        }

        &--left {
            flex: 1 1 66.667%;
            margin-right: 9px;
            @include mq(null, $w1440) {
                margin-right: get-vw(9, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-bottom: get-vw(9, $medium-vw);
                margin-right: 0;
            }
            @include mq(null, $w375) {
                margin-bottom: get-vw(13.5, $medium-vw);
            }
        }

        &--right {
            flex: 1 0 33.333%;
            margin-left: 9px;
            @include mq(null, $w1440) {
                margin-left: get-vw(9, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(9, $medium-vw);
                margin-left: 0;
            }
            @include mq(null, $w375) {
                margin-top: get-vw(13.5, $medium-vw);
            }
        }

        &--checkout {
            &--header {
                margin: 40px 0;
                position: relative;
                @include mq(null, $w1440) {
                    margin: get-vw(40, $regofit-vw) 0;
                }
                @include mq(null, $w768) {
                    margin: get-vw(40, $medium-vw) 0;
                }
                @include mq(null, $w375) {
                    margin: get-vw(60, $medium-vw) 0;
                }

                &--title {
                    @include poppins(700);
                    @include font-size-lh(30, 45);
                    color: $black;
                    text-transform: uppercase;
                    display: inline-flex;
                    align-items: center;
                    margin: 0;
                    padding-right: 8px;
                    background-color: $white;
                    position: relative;
                    z-index: 2;
                    @include mq(null, $w1440) {
                        @include font-size-vw(30, 45, $regofit-vw);
                        padding-right: get-vw(8, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(30, 45, $medium-vw);
                        padding-right: get-vw(8, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(45, 67.5, $medium-vw);
                        padding-right: get-vw(12, $medium-vw);
                    }
                }

                &--edit {
                    @include poppins(500);
                    @include font-size-lh(20, 30);
                    color: $black;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    z-index: 9;
                    transform: translateY(-50%);
                    padding-left: 20px;
                    background-color: $white;
                    cursor: pointer;
                    display: none;
                    transition: all 0.3s;
                    @include mq(null, $w1440) {
                        @include font-size-vw(20, 30, $regofit-vw);
                        padding-left: get-vw(20, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(20, 30, $medium-vw);
                        padding-left: get-vw(20, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(30, 45, $medium-vw);
                        padding-left: get-vw(30, $medium-vw);
                    }

                    .checkout-step.-complete:not(.-current) & {
                        display: block !important;
                    }
                }

                &--number {
                    .checkout-step.-complete & {
                        display: none;
                    }
                }

                .fa-check {
                    color: $cyan;
                    margin-right: 20px;
                    display: none;
                    transition: all 0.3s;
                    @include mq(null, $w1440) {
                        margin-right: get-vw(20, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        margin-right: get-vw(20, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        margin-right: get-vw(30, $medium-vw);
                    }

                    .checkout-step.-complete & {
                        display: block;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    left: 0;
                    background-color: $cyan;
                    width: 100%;
                    height: 5px;
                    transform: translateY(-50%);
                    @include mq(null, $w1440) {
                        height: get-vw(5, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        height: get-vw(5, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        height: get-vw(7.5, $medium-vw);
                    }
                }
            }

            &--text {
                @include worksans();
                @include font-size-lh(20, 23);
                color: $navy-blue;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }
            }

            &--personal-information {
                display: flex;
                justify-content: center;
                align-items: flex-start;
            }

            &--content {
                display: none;
                transition: all 0.3s;

                .checkout-step.-current & {
                    display: block;
                }
            }

            &--inner {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                @include mq(null, $w768) {
                    flex-wrap: wrap;
                }
            }

            &--process {
                width: 66.666%;
                margin-right: 20px;
                @include mq(null, $w1440) {
                    margin-right: get-vw(20, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: 100%;
                    margin-bottom: get-vw(20, $medium-vw);
                    margin-right: 0;
                }
                @include mq(null, $w375) {
                    margin-bottom: get-vw(30, $medium-vw);
                }
            }

            &--summary {
                width: 33.333%;
                margin-left: 20px;
                @include mq(null, $w1440) {
                    margin-left: get-vw(20, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: 100%;
                    margin-top: get-vw(20, $medium-vw);
                    margin-left: 0;
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(30, $medium-vw);
                }
            }
        }
    }

    &--order--confirmation {
        &--header {
            margin-bottom: 40px;
            @include mq(null, $w1440) {
                margin-bottom: get-vw(40, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-bottom: get-vw(40, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-bottom: get-vw(60, $medium-vw);
            }
        }
        &--table {
            display: flex;
            justify-content: space-between;
            @include mq(null, $w768) {
                flex-direction: column;
            }

            .cart--products-details {
                flex: 1 1 66.667%;
                margin-right: 9px;
                @include mq(null, $w1440) {
                    margin-right: get-vw(9, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-bottom: get-vw(9, $medium-vw);
                    margin-right: 0;
                }
                @include mq(null, $w375) {
                    margin-bottom: get-vw(13.5, $medium-vw);
                }
            }

            .cart--summary {
                flex: 1 1 33.333%;
                margin-left: 9px;
                @include mq(null, $w1440) {
                    margin-left: get-vw(9, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-top: get-vw(9, $medium-vw);
                    margin-left: 0;
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(13.5, $medium-vw);
                }
            }
        }

        &--hook-payment-return--description p {
            @include worksans();
            @include font-size-lh(20, 23);
            color: $navy-blue;
            @include mq(null, $w1440) {
                @include font-size-vw(20, 23, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(20, 23, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(30, 34.5, $medium-vw);
            }
        }
    }
}

.cart {
    &--products-details,
    &--summary {
        background-color: $white;
        box-shadow: 0 0 5px rgba($black, 0.16);
        @include mq(null, $w1440) {
            box-shadow: 0 0 get-vw(5, $regofit-vw) rgba($black, 0.16);
        }
        @include mq(null, $w768) {
            box-shadow: 0 0 get-vw(5, $medium-vw) rgba($black, 0.16);
        }
        @include mq(null, $w375) {
            box-shadow: 0 0 get-vw(7.5, $medium-vw) rgba($black, 0.16);
        }
    }

    &--summary,
    &--associated-products {
        &--header {
            background-color: $cyan;
            padding: 15px 20px;
            display: flex;
            justify-content: center;
            @include mq(null, $w1440) {
                padding: get-vw(15, $regofit-vw) get-vw(20, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: get-vw(15, $medium-vw) get-vw(20, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: get-vw(15, $medium-vw) get-vw(20, $medium-vw);
            }

            &--title {
                @include worksans(700);
                @include font-size-lh(30, 36);
                color: $white;
                @include mq(null, $w1440) {
                    @include font-size-vw(30, 36, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(30, 36, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(45, 54, $medium-vw);
                }
            }
        }

        &--inner {
            padding: 12px;
            @include mq(null, $w1440) {
                padding: get-vw(12, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: get-vw(12, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: get-vw(18, $medium-vw);
            }
        }
    }

    &--products-details {
        &--inner {
            padding: 22px;
            @include mq(null, $w1440) {
                padding: get-vw(22, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: get-vw(22, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: get-vw(33, $medium-vw);
            }
        }

        &--items {
            padding-right: 27px;
            @include mq(null, $w1440) {
                padding-right: get-vw(27, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding-right: 0;
            }
        }

        &--item {
            padding: 20px 0;
            border-top: 1px solid rgba($black, 0.16);
            @include mq(null, $w1440) {
                padding: get-vw(20, $regofit-vw) 0;
            }
            @include mq(null, $w768) {
                padding: get-vw(20, $medium-vw) 0;
            }
            @include mq(null, $w375) {
                padding: get-vw(20, $medium-vw) 0;
            }

            &:first-child {
                padding-top: 0;
                border-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        &--no-items {
            @include worksans(700);
            @include font-size-lh(25, 30);
            color: $navy-blue;
            letter-spacing: 1px;
            @include mq(null, $w1440) {
                @include font-size-vw(25, 30, $regofit-vw);
            }
            @include mq(null, $w768) {
                @include font-size-vw(25, 30, $medium-vw);
            }
            @include mq(null, $w375) {
                @include font-size-vw(37.5, 45, $medium-vw);
            }
        }

        &--product {
            display: flex;
            $widthImage: 225;
            @include mq(null, $w768) {
                align-items: center;
            }

            &--image {
                width: #{$widthImage}px;
                height: #{$widthImage}px;
                @include mq(null, $w1440) {
                    width: get-vw($widthImage, $regofit-vw);
                    height: get-vw($widthImage, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: get-vw($widthImage, $medium-vw);
                    height: get-vw($widthImage, $medium-vw);
                }
                //@include mq(null, $w375) {
                //    width: get-vw(($widthImage * 1.5), $medium-vw);
                //    height: get-vw(($widthImage * 1.5), $medium-vw);
                //}

                img {
                    object-fit: cover;
                    object-position: 50% 50%;
                    width: 100%;
                    height: 100%;
                }
            }

            &--content {
                width: calc(100% - 225px);
                padding-left: 40px;
                @include mq(null, $w1440) {
                    $calc: get-vw($widthImage, $regofit-vw);
                    width: calc(100% - #{$calc});
                }
                @include mq(null, $w768) {
                    $calc: get-vw($widthImage, $medium-vw);
                    width: calc(100% - #{$calc});
                }
                //@include mq(null, $w375) {
                //    $calc: get-vw(($widthImage * 1.5), $medium-vw);
                //    width: calc(100% - #{$calc});
                //}
            }

            &--remove {
                display: flex;
                justify-content: flex-end;

                .picto-trash {
                    width: 35px;
                    height: 37px;
                    @include mq(null, $w1440) {
                        width: get-vw(35, $regofit-vw);
                        height: get-vw(37, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        width: get-vw(35, $medium-vw);
                        height: get-vw(37, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        width: get-vw(52.5, $medium-vw);
                        height: get-vw(55.5, $medium-vw);
                    }
                }
            }

            &--title {
                @include worksans(700);
                @include font-size-lh(30, 36);
                color: $navy-blue;
                letter-spacing: 1px;
                margin-top: 8px;
                @include mq(null, $w1440) {
                    @include font-size-vw(30, 36, $regofit-vw);
                    margin-top: get-vw(8, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(30, 36, $medium-vw);
                    margin-top: get-vw(8, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(45, 54, $medium-vw);
                    margin-top: get-vw(8, $medium-vw);
                }
            }

            &--description {
                @include worksans(500);
                @include font-size-lh(14, 17);
                color: rgba($navy-blue, 0.45);
                text-transform: uppercase;
                @include mq(null, $w1440) {
                    @include font-size-vw(14, 17, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(14, 17, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(21, 25.5, $medium-vw);
                }
            }

            &--attributes {
                margin-top: 10px;
                @include mq(null, $w1440) {
                    margin-top: get-vw(10, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-top: get-vw(10, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(15, $medium-vw);
                }

                &--item {
                    @include worksans(500);
                    @include font-size-lh(14, 17);
                    color: rgba($navy-blue, 0.45);
                    margin-top: 5px;
                    @include mq(null, $w1440) {
                        @include font-size-vw(14, 17, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(14, 17, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(21, 25.5, $medium-vw);
                    }
                }
            }

            &--quantity-price {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-top: 30px;
                @include mq(null, $w1440) {
                    margin-top: get-vw(30, $regofit-vw);
                }
                @include mq(null, $w768) {
                    flex-direction: column;
                    margin-top: get-vw(30, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(45, $medium-vw);
                }
            }

            &--quantity {
                @include mq(null, $w768) {
                    width: 100%;
                    margin-bottom: get-vw(15, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-bottom: get-vw(22.5, $medium-vw);
                }

                &--value {
                    @include worksans(700);
                    @include font-size-lh(30, 36);
                    color: $navy-blue;
                    @include mq(null, $w1440) {
                        @include font-size-vw(30, 36, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(30, 36, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(45, 54, $medium-vw);
                    }
                }
                .bootstrap-touchspin {
                    box-shadow: none;
                    float: none;

                    input {
                        &.form-control,
                        &.form-group {
                            @include worksans(700);
                            @include font-size-lh(20, 23);
                            color: $black;
                            text-align: right;
                            width: 80px;
                            height: 42px;
                            padding: 0 20px;
                            border: 2px solid rgba($border, 0.09);
                            border-right: none;
                            transition: all 0.3s;
                            @include mq(null, $w1440) {
                                @include font-size-vw(20, 23, $regofit-vw);
                                width: get-vw(80, $regofit-vw);
                                height: get-vw(42, $regofit-vw);
                                padding: 0 get-vw(20, $regofit-vw);
                            }
                            @include mq(null, $w768) {
                                @include font-size-vw(20, 23, $medium-vw);
                                width: get-vw(80, $medium-vw);
                                height: get-vw(42, $medium-vw);
                                padding: 0 get-vw(20, $medium-vw);
                            }
                            @include mq(null, $w375) {
                                @include font-size-vw(30, 34.5, $medium-vw);
                                width: get-vw(120, $medium-vw);
                                height: get-vw(63, $medium-vw);
                                padding: 0 get-vw(30, $medium-vw);
                            }

                            &::placeholder {
                                color: rgba($black, 0.45);
                            }
                        }
                    }

                    .btn-touchspin {
                        color: $white;
                        background-color: $cyan;
                        border:none;
                        height: 21px;
                        width: 21px;
                        @include mq(null, $w1440) {
                            height: get-vw(21, $regofit-vw);
                            width: get-vw(21, $regofit-vw);
                        }
                        @include mq(null, $w768) {
                            height: get-vw(21, $medium-vw);
                            width: get-vw(21, $medium-vw);
                        }
                        @include mq(null, $w375) {
                            height: get-vw(31.5, $medium-vw);
                            width: get-vw(31.5, $medium-vw);
                        }
                    }

                    .input-group-btn-vertical {
                        i {
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }

            &--prices {
                @include mq(null, $w768) {
                    width: 100%;
                }
            }

            &--price {
                @include worksans(500);
                @include font-size-lh(25, 29);
                color: $cyan;
                text-align: right;
                letter-spacing: 1px;
                @include mq(null, $w1440) {
                    @include font-size-vw(25, 29, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(25, 29, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(37.5, 43.5, $medium-vw);
                }

                &--total {
                    @include worksans(700);
                    @include font-size-lh(35, 41);
                    color: $cyan;
                    letter-spacing: 1px;
                    @include mq(null, $w1440) {
                        @include font-size-vw(35, 41, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(35, 41, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(52.5, 61.5, $medium-vw);
                    }
                }
            }
        }
    }

    &--summary {
        &--detailed-totals {
            margin-top: 20px;
            margin-bottom: 50px;
            @include mq(null, $w1440) {
                margin-top: get-vw(20, $regofit-vw);
                margin-bottom: get-vw(50, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(20, $medium-vw);
                margin-bottom: get-vw(50, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-top: get-vw(30, $medium-vw);
                margin-bottom: get-vw(75, $medium-vw);
            }
        }

        &--subtotals,
        &--totals,
        &--order-details {
            &--line {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding: 10px 0;
                @include mq(null, $w1440) {
                    padding: get-vw(10, $regofit-vw) 0;
                }
                @include mq(null, $w768) {
                    padding: get-vw(10, $medium-vw) 0;
                }
                @include mq(null, $w375) {
                    padding: get-vw(15, $medium-vw) 0;
                }
            }

            &--label,
            &--value {
                width: 50%;
            }
        }

        &--subtotals,
        &--order-details {
            &--label {
                @include worksans(500);
                @include font-size-lh(20, 23);
                color: $navy-blue;
                letter-spacing: 1px;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }
            }

            &--value {
                @include worksans(500);
                @include font-size-lh(20, 23);
                color: $navy-blue;
                text-align: right;
                letter-spacing: 1px;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }

                .cart--summary--subtotals--shipping & {
                    @include worksans(700);
                    text-transform: uppercase;
                }
            }
        }

        &--totals {
            &--label {
                @include google-sans-bold;
                @include font-size-lh(30, 37);
                color: rgba($black, 0.87);
                letter-spacing: 1px;
                @include mq(null, $w1440) {
                    @include font-size-vw(30, 37, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(30, 37, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(45, 55.5, $medium-vw);
                }
            }

            &--value {
                @include google-sans-bold;
                @include font-size-lh(30, 37);
                color: rgba($cyan, 0.87);
                text-align: right;
                letter-spacing: 1px;
                @include mq(null, $w1440) {
                    @include font-size-vw(30, 37, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(30, 37, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(45, 55.5, $medium-vw);
                }
            }
        }

        &--block-promo {
            margin-top: 30px;
            margin-bottom: 0;
            @include mq(null, $w1440) {
                margin-top: get-vw(30, $regofit-vw);
            }
            @include mq(null, $w768) {
                margin-top: get-vw(30, $medium-vw);
            }
            @include mq(null, $w375) {
                margin-top: get-vw(30, $medium-vw);
            }
        }

        &--voucher {
            &--line {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding: 10px 0;
                @include mq(null, $w1440) {
                    padding: get-vw(10, $regofit-vw) 0;
                }
                @include mq(null, $w768) {
                    padding: get-vw(10, $medium-vw) 0;
                }
                @include mq(null, $w375) {
                    padding: get-vw(15, $medium-vw) 0;
                }
            }

            &--label,
            &--actions {
                width: 50%;
            }

            &--label {
                @include worksans(500);
                @include font-size-lh(20, 23);
                color: $navy-blue;
                letter-spacing: 1px;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }
            }

            &--actions {
                display: flex;
                justify-content: flex-end;
            }

            &--price {
                @include worksans(500);
                @include font-size-lh(20, 23);
                color: $cyan;
                text-transform: uppercase;
                text-align: right;
                letter-spacing: 1px;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.5, $medium-vw);
                }
            }

            &--remove {
                margin-left: 5px;
                @include mq(null, $w1440) {
                    margin-left: get-vw(5, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-left: get-vw(5, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-left: get-vw(7.5, $medium-vw);
                }

                .picto-trash {
                    width: 21px;
                    height: 23px;
                    @include mq(null, $w1440) {
                        width: get-vw(21, $regofit-vw);
                        height: get-vw(23, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        width: get-vw(21, $medium-vw);
                        height: get-vw(23, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        width: get-vw(31.5, $medium-vw);
                        height: get-vw(34.5, $medium-vw);
                    }
                }
            }

            &--promo-code-button {
                margin-top: 20px;
                padding: 0;
                @include mq(null, $w1440) {
                    margin-top: get-vw(20, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-top: get-vw(20, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(30, $medium-vw);
                }
            }

            &--promo-code {
                margin-top: 20px;
                @include mq(null, $w1440) {
                    margin-top: get-vw(20, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-top: get-vw(20, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(30, $medium-vw);
                }

                &--inner {
                    padding: 0;
                }

                &--form {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &--input {
                    @include worksans(700);
                    @include font-size-lh(20, 23);
                    color: $black;
                    width: 100%;
                    height: 60px;
                    padding: 0 30px;
                    flex: 1 1 100%;
                    border: 2px solid rgba($border, 0.09);
                    border-right: none;
                    transition: all 0.3s;
                    @include mq(null, $w1440) {
                        @include font-size-vw(20, 23, $regofit-vw);
                        height: get-vw(60, $regofit-vw);
                        padding: 0 get-vw(30, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(20, 23, $medium-vw);
                        height: get-vw(60, $medium-vw);
                        padding: 0 get-vw(30, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(30, 34.5, $medium-vw);
                        height: get-vw(90, $medium-vw);
                        padding: 0 get-vw(45, $medium-vw);
                    }

                    &::placeholder {
                        color: rgba($black, 0.45);
                    }

                    &:focus {
                        border-color: $border;
                    }
                }

                &--button {
                    @include worksans(700);
                    @include font-size-lh(20, 23);
                    color: $white;
                    background-color: $cyan;
                    height: 60px;
                    border: 3px solid $cyan;
                    flex: 1 0 120px;
                    @include mq(null, $w1440) {
                        @include font-size-vw(20, 23, $regofit-vw);
                        height: get-vw(60, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(20, 23, $medium-vw);
                        height: get-vw(60, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(30, 34.5, $medium-vw);
                        height: get-vw(90, $medium-vw);
                    }
                }

                &--cancel {
                    margin-top: 20px;
                    @include mq(null, $w1440) {
                        margin-top: get-vw(20, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        margin-top: get-vw(20, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        margin-top: get-vw(30, $medium-vw);
                    }
                }
            }
        }

        &--cta {
            display: flex;
            justify-content: center;

            &--shopping,
            &--order {
                border: 3px solid $cyan;
                width: 250px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include mq(null, $w1440) {
                    width: get-vw(250, $regofit-vw);
                    height: get-vw(60, $regofit-vw);
                }
                @include mq(null, $w768) {
                    width: get-vw(250, $medium-vw);
                    height: get-vw(60, $medium-vw);
                }
                @include mq(null, $w375) {
                    width: get-vw(375, $medium-vw);
                    height: get-vw(90, $medium-vw);
                }
            }

            &--order {
                box-shadow: 0 3px 6px rgba($black, 0.16);
                margin-left: 6px;
                @include mq(null, $w1440) {
                    box-shadow: 0 get-vw(3, $regofit-vw) get-vw(6, $regofit-vw) rgba($black, 0.16);
                    margin-left: get-vw(6, $regofit-vw);
                }
                @include mq(null, $w768) {
                    box-shadow: 0 get-vw(3, $medium-vw) get-vw(6, $medium-vw) rgba($black, 0.16);
                    margin-left: get-vw(6, $medium-vw);
                }
                @include mq(null, $w375) {
                    box-shadow: 0 get-vw(4.5, $medium-vw) get-vw(9, $medium-vw) rgba($black, 0.16);
                    margin-left: get-vw(9, $medium-vw);
                }
            }

            &--shopping {
                margin-right: 6px;
                @include mq(null, $w1440) {
                    margin-right: get-vw(6, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-right: get-vw(6, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-right: get-vw(9, $medium-vw);
                }
            }

            &--button {
                @include worksans();
                @include font-size-lh(20, 23);
                color: $cyan;
                letter-spacing: 1px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 23, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 23, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 34.53, $medium-vw);
                }

                .cart--summary--cta--order & {
                    color: $white;
                    background-color: $cyan;
                    border: none;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &--associated-products {
        position: relative;

        &--inner {
            padding: 0 60px;
            margin-top: 20px;
            @include mq(null, $w1440) {
                padding: 0 get-vw(60, $regofit-vw);
                margin-top: get-vw(20, $regofit-vw);
            }
            @include mq(null, $w768) {
                padding: 0 get-vw(60, $medium-vw);
                margin-top: get-vw(20, $medium-vw);
            }
            @include mq(null, $w375) {
                padding: 0 get-vw(90, $medium-vw);
                margin-top: get-vw(30, $medium-vw);
            }
        }

        .slick-list {
            overflow: hidden;
        }

        .slick-track {
            display: flex;
        }

        &--slick--arrow {
            position: absolute;
            top: 50%;
            border: none;
            background-color: transparent;

            &--prev {
                left: -20px;
                transform: rotate(-90deg);
                @include mq(null, $w1440) {
                    left: get-vw(-20, $regofit-vw);
                }
                @include mq(null, $w768) {
                    left: get-vw(-20, $medium-vw);
                }
                @include mq(null, $w375) {
                    left: get-vw(-60, $medium-vw);
                }
            }

            &--next {
                right: -20px;
                transform: rotate(90deg);
                @include mq(null, $w1440) {
                    right: get-vw(-20, $regofit-vw);
                }
                @include mq(null, $w768) {
                    right: get-vw(-20, $medium-vw);
                }
                @include mq(null, $w375) {
                    right: get-vw(-60, $medium-vw);
                }
            }
        }

        &--product {
            margin: 0 15px;
            padding: 15px;
            box-shadow: 0 3px 6px rgba($black, 0.16);
            display: flex;
            flex-direction: column;
            align-items: center;
            @include mq(null, $w1440) {
                margin: 0 get-vw(15, $regofit-vw);
                padding: get-vw(15, $regofit-vw);
                box-shadow: 0 get-vw(3, $regofit-vw) get-vw(6, $regofit-vw) rgba($black, 0.16);
            }
            @include mq(null, $w768) {
                margin: 0 get-vw(15, $medium-vw);
                padding: get-vw(15, $medium-vw);
                box-shadow: 0 get-vw(3, $medium-vw) get-vw(6, $medium-vw) rgba($black, 0.16);
            }
            @include mq(null, $w375) {
                margin: 0 get-vw(22.5, $medium-vw);
                padding: get-vw(22.5, $medium-vw);
                box-shadow: 0 get-vw(4.5, $medium-vw) get-vw(9, $medium-vw) rgba($black, 0.16);
            }

            &--name {
                @include worksans(700);
                @include font-size-lh(25, 30);
                color: $navy-blue;
                letter-spacing: 1px;
                margin-top: 15px;
                @include mq(null, $w1440) {
                    @include font-size-vw(25, 30, $regofit-vw);
                    margin-top: get-vw(15, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(25, 30, $medium-vw);
                    margin-top: get-vw(15, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(37.5, 45, $medium-vw);
                    margin-top: get-vw(22.5, $medium-vw);
                }
            }

            &--variant {
                @include worksans(500);
                @include font-size-lh(20, 24);
                color: $navy-blue;
                letter-spacing: 1px;
                margin-top: 5px;
                @include mq(null, $w1440) {
                    @include font-size-vw(20, 24, $regofit-vw);
                    margin-top: get-vw(5, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(20, 24, $medium-vw);
                    margin-top: get-vw(5, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(30, 36, $medium-vw);
                    margin-top: get-vw(7.5, $medium-vw);
                }
            }

            &--price {
                @include worksans(700);
                @include font-size-lh(25, 30);
                color: $cyan;
                letter-spacing: 1px;
                @include mq(null, $w1440) {
                    @include font-size-vw(25, 30, $regofit-vw);
                }
                @include mq(null, $w768) {
                    @include font-size-vw(25, 30, $medium-vw);
                }
                @include mq(null, $w375) {
                    @include font-size-vw(37.5, 45, $medium-vw);
                }
            }

            &--actions {
                margin-top: 15px;
                @include mq(null, $w1440) {
                    margin-top: get-vw(15, $regofit-vw);
                }
                @include mq(null, $w768) {
                    margin-top: get-vw(15, $medium-vw);
                }
                @include mq(null, $w375) {
                    margin-top: get-vw(22.5, $medium-vw);
                }

                &--add-to-cart--button {
                    @include worksans();
                    @include font-size-lh(20, 23);
                    color: $white;
                    background-color: $cyan;
                    border: none;
                    padding: 10px 15px;
                    @include mq(null, $w1440) {
                        @include font-size-vw(20, 23, $regofit-vw);
                        padding: get-vw(10, $regofit-vw) get-vw(15, $regofit-vw);
                    }
                    @include mq(null, $w768) {
                        @include font-size-vw(20, 23, $medium-vw);
                        padding: get-vw(10, $medium-vw) get-vw(15, $medium-vw);
                    }
                    @include mq(null, $w375) {
                        @include font-size-vw(30, 34.5, $medium-vw);
                        padding: get-vw(15, $medium-vw) get-vw(22.5, $medium-vw);
                    }
                }
            }
        }
    }
}