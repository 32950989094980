$sans-serif: Tahoma, Arial, sans-serif;
$serif: "Times New Roman", Times, serif;
$arial: Arial, Tahoma, sans-serif;;
$times: $serif;
@mixin arial($font-weight: 400) {
    font-family: $arial;
    font-weight: $font-weight;
}
$google-sans-bold: 'GoogleSans-Bold', $sans-serif;
@mixin google-sans-bold() {
    font-family: $poppins;
}
@mixin times($font-weight: 400) {
    font-family: $times;
    font-weight: $font-weight;
}
$poppins: 'Poppins', $sans-serif;
@mixin poppins($font-weight: 400) {
    font-family: $poppins;
    font-weight: $font-weight;
}
$raleway: 'Raleway', $sans-serif;
@mixin raleway($font-weight: 400) {
    font-family: $raleway;
    font-weight: $font-weight;
}
$worksans: 'Work Sans', $sans-serif;
@mixin worksans($font-weight: 400) {
    font-family: $worksans;
    font-weight: $font-weight;
}